import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import { taskKeys, RequestTypes } from '@core/react-query/features/tasks/task'
import { tasksKeys } from '@core/react-query/features/tasks/tasks'
import { useMutateQueryData } from '@core/react-query/hooks'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { ITask } from '@core/entities/task/Task'
import { OfflineEntityType } from '@core/react-query/defaultMutations'

export const getUpdateTaskDraftMutationOptions = (
  queryClient?: QueryClient,
) => {
  const { updateInfiniteQueryItem } = useMutateQueryData(queryClient)
  return {
    ...taskKeys.updateTaskDraft,
    meta: {
      name: 'Update Task Draft',
      offlineEnabled: true,
      variablesType: OfflineEntityType.TASK_SUBMISSION,
    },
    onSuccess: (task) => {
      updateInfiniteQueryItem(
        tasksKeys.draftTasks({
          organisationId: task.template.organisationId,
          projectIds: [task.projectId],
        }).queryKey,
        task,
      )
    },
  }
}

export const useUpdateTaskDraft = () => {
  const queryClient = useQueryClient()
  return useMutation<ITask, RequestErrorType, RequestTypes['updateTaskDraft']>(
    getUpdateTaskDraftMutationOptions(queryClient),
  )
}
