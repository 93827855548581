import React from 'react'
import { Flex, Text } from '@fundamentals'
import { Card, MenuItem, Tooltip } from '@common/material'

interface GenericComponentType<T> {
  item: T
  dataTestId?: string
  onSelect: () => void
  onDragStart?: (event: React.DragEvent<HTMLLIElement>) => void
  renderIcon: (item: T) => React.ReactNode
  renderText: (item: T) => React.ReactNode
  disabled?: boolean
  tooltip?: string | React.ReactNode
}

export const GenericMenuItem = <T,>({
  item,
  dataTestId,
  onSelect,
  onDragStart,
  renderIcon,
  renderText,
  disabled,
  tooltip,
}: GenericComponentType<T>) => {
  return (
    <Tooltip title={tooltip}>
      <span>
        <MenuItem
          sx={{ p: 0, mb: '0.5rem' }}
          onClick={onSelect}
          draggable={!!onDragStart}
          onDragStart={onDragStart}
          data-test={dataTestId}
          disabled={disabled}
        >
          <Card sx={{ width: '100%', backgroundColor: 'transparent' }}>
            <Flex py={1.5} px={1}>
              {renderIcon(item)}
              <Text pl='0.25rem'>{renderText(item)}</Text>
            </Flex>
          </Card>
        </MenuItem>
      </span>
    </Tooltip>
  )
}
