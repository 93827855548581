import { useQuery } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { UseQueryOptions } from '@tanstack/react-query/src/types'
import { taskTemplateKeys } from '../taskTemplateKeys'
import { TaskTemplate, ITaskTemplate } from '@core/entities/task/TaskTemplate'
import { PageResponse } from '@core/entities/pagination'

const dataTransformer = (response: PageResponse<ITaskTemplate>) => {
  return {
    ...response,
    content: response.content.map((template) => TaskTemplate.new(template)),
  }
}

export const useGetTaskTemplates = (
  payload: RequestTypes['getTaskTemplates'],
  options?: UseQueryOptions<
    PageResponse<ITaskTemplate>,
    string,
    PageResponse<TaskTemplate>
  >,
) => {
  return useQuery<
    PageResponse<ITaskTemplate>,
    string,
    PageResponse<TaskTemplate>
  >({
    select: dataTransformer,
    ...taskTemplateKeys.byOrganisation(payload),
    ...options,
  })
}
