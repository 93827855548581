import { IAvailableTaskTemplatesWrapper } from '@core/entities/task/TaskTemplate/IAvailableTaskTemplateWrapper'
import { Entity } from '@core/entities/entity'
import { plainToInstance, Type } from 'class-transformer'
import { TaskTemplate } from '@core/entities/task/TaskTemplate/TaskTemplate'

export class AvailableTaskTemplatesWrapper extends Entity<IAvailableTaskTemplatesWrapper> {
  @Type(() => TaskTemplate)
  recent: Array<TaskTemplate>

  @Type(() => TaskTemplate)
  templates: Array<TaskTemplate>

  public static new(payload: unknown): AvailableTaskTemplatesWrapper {
    const entity = plainToInstance<AvailableTaskTemplatesWrapper, unknown>(
      AvailableTaskTemplatesWrapper,
      payload,
    )

    return entity
  }
}
