import { RegisterItemContentVariant } from '@modules/registers/items/types'
import { Chip } from '@common/material'
import { RegisterItemText } from '@modules/registers/items/RegisterItemText'
import { RegisterItemContentButtons } from '@modules/registers/propertyValue/RegisterItemPropertyValueButtons'
import { Box } from '@fundamentals'
import { alpha } from '@mui/material'
import { RegisterItemSelectProperty } from '@core/entities/register/RegisterItem'
import { emptyValue } from '@modules/registers/propertyValue/helpers'

type RegisterItemPropertySelectValueProps = {
  registerItemPropertyData: RegisterItemSelectProperty<string>
  variant?: RegisterItemContentVariant
}

export const RegisterItemPropertySelectValue: React.FC<
  RegisterItemPropertySelectValueProps
> = ({ registerItemPropertyData, variant }) => {
  const selectColor =
    registerItemPropertyData?.type === 'SELECT' &&
    registerItemPropertyData?.selectedOption?.colour &&
    alpha(registerItemPropertyData?.selectedOption?.colour, 0.7)
  if (!registerItemPropertyData?.selectedOption?.label) {
    return <RegisterItemText>{emptyValue(variant)}</RegisterItemText>
  }

  const selectInner = (
    <Chip
      sx={{
        padding: 1,
        height: variant === 'picker' ? '15px' : 'auto',
        maxHeight: '100%',
        '& .MuiChip-label': {
          overflowWrap: 'break-word',
          whiteSpace: 'normal',
          height: 'auto',
          lineHeight: 1.2,
        },
      }}
      variant={'outlined'}
      icon={
        <Box
          height={10}
          minHeight={10}
          maxHeight={10}
          minWidth={10}
          maxWidth={10}
          borderRadius={'50%'}
          bgcolor={selectColor}
        />
      }
      label={registerItemPropertyData?.selectedOption?.label}
    />
  )

  return (
    <RegisterItemContentButtons
      variant={variant}
      value={registerItemPropertyData?.selectedOption?.label}
      registerItemPropertyData={registerItemPropertyData}
    >
      {selectInner}
    </RegisterItemContentButtons>
  )
}
