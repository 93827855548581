import { PageResponse } from '@core/entities/pagination'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { tasksKeys, RequestTypes } from '@core/react-query/features/tasks/tasks'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { Task, ITask } from '@core/entities/task/Task'

const dataTransformer = (response: PageResponse<ITask>) => {
  return {
    ...response,
    content: response.content.map((task) => Task.new(task)),
  }
}

export const useGetAllTasks = (
  payload: RequestTypes['getAllTasks'],
  options: UseQueryOptions<
    PageResponse<ITask>,
    RequestErrorType,
    PageResponse<Task>
  > = {},
) => {
  return useQuery({
    select: dataTransformer,
    ...tasksKeys.allTasks(payload),
    ...options,
  })
}
