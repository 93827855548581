import { Entity } from '@core/entities/entity'
import { IPermitTemplateVersion } from '@core/entities/permit-template-editor/IPermitTemplateVersion'
import { User } from '@core/entities/user'
import { plainToInstance, Type } from 'class-transformer'
import { localizedStrings } from '@core/strings'
import {
  TemplateEditorPermitTemplateInitiatorRole,
  TemplateEditorPermitTemplateVersionStatus,
} from '@core/entities/permit-template-editor/types'
import { TemplateForm } from '@core/entities/template/TemplateForm'
import { PermitTemplateApproval } from '@core/entities/permit-template-editor/PermitTemplateApproval'
import { PermitTemplateFinalSignOff } from './PermitTemplateFinalSignOff'
import { TemplateQuestion } from '@core/entities/template/TemplateQuestion'
import { TemplateWarning } from '@core/entities/template/TemplateWarnings'

export class TemplateEditorPermitTemplateVersion extends Entity<IPermitTemplateVersion> {
  id?: number

  @Type(() => User)
  createdBy: User

  createdOn?: string

  @Type(() => User)
  modifiedBy: User

  modifiedOn?: string

  version: number

  templateId: number

  status: TemplateEditorPermitTemplateVersionStatus

  initiatorRole: TemplateEditorPermitTemplateInitiatorRole

  approverConfirmationText: string

  maxDurationEnabled: boolean

  clonable?: boolean

  permitHolderTransferEnabled: boolean

  calculateDurationBasedOnDays: boolean

  allowExtensionBeyondMaxDuration: boolean

  @Type(() => PermitTemplateApproval)
  approvals: PermitTemplateApproval

  @Type(() => PermitTemplateFinalSignOff)
  finalSignOffs: PermitTemplateFinalSignOff

  permitHolderSelfApprove: boolean

  requiresFinalSignoff: boolean

  @Type(() => TemplateForm)
  permitHolderQuestions: TemplateForm

  @Type(() => TemplateForm)
  postPermitChecksQuestions: TemplateForm

  @Type(() => TemplateForm)
  authorisedPersonQuestions: TemplateForm

  skipPostPermitChecks: boolean

  requesterConfirmationText: string

  requesterConfirmationCountdown: { time: number; name: string }

  maxDurationSeconds?: number

  maxDurationDays?: number

  requiresApproval?: boolean

  requiresBriefingVerification?: boolean

  requireGeolocationForBriefings?: boolean

  requireGeolocationForPermit?: boolean

  requiresTransferApproval?: boolean

  allowSuspension: boolean

  allowShorten: boolean

  allowRequestExtension: boolean

  allowApprovalDrafts: boolean

  @Type(() => TemplateWarning)
  globalWarnings: TemplateWarning[]

  @Type(() => TemplateWarning)
  questionWarnings: TemplateWarning[]

  public isActive(): boolean {
    return this.status === 'ACTIVE'
  }

  public isReadOnly(): boolean {
    return this.status !== 'DRAFT'
  }

  public static new(payload: unknown): TemplateEditorPermitTemplateVersion {
    const entity = plainToInstance(TemplateEditorPermitTemplateVersion, payload)

    return entity
  }

  public static statusColor(status: TemplateEditorPermitTemplateVersionStatus) {
    switch (status) {
      case 'ACTIVE':
        return 'success'
      case 'ARCHIVED':
        return 'default'
      case 'DISCARDED':
        return 'warning'
      case 'DRAFT':
        return 'default'
      default:
        return 'default'
    }
  }

  public static statusText(status: TemplateEditorPermitTemplateVersionStatus) {
    switch (status) {
      case 'ACTIVE':
        return localizedStrings.active
      case 'ARCHIVED':
        return localizedStrings.archived
      case 'DISCARDED':
        return localizedStrings.discarded
      case 'DRAFT':
        return localizedStrings.draft
      default:
        return status
    }
  }

  public getQuestionInfo(questionId: string) {
    const getInfo = (templateQuestions: TemplateForm, sourceText) => {
      const groupIndex = templateQuestions.questions.findIndex(
        (questionGroup) => questionGroup.data.find((q) => q.id === questionId),
      )
      const questionIndex = templateQuestions.questions[
        groupIndex
      ].data.findIndex((q) => q.id === questionId)
      return {
        source: sourceText,
        groupIndex,
        questionIndex,
        body: TemplateQuestion.getBodyFormattedText(
          templateQuestions.questions[groupIndex].data[questionIndex].body,
        ),
      }
    }

    if (
      this.permitHolderQuestions.questions.find((questionGroup) =>
        questionGroup.data.find((q) => q.id === questionId),
      )
    ) {
      return getInfo(this.permitHolderQuestions, localizedStrings.permitHolder)
    }
    if (
      this.postPermitChecksQuestions.questions.find((questionGroup) =>
        questionGroup.data.find((q) => q.id === questionId),
      )
    ) {
      return getInfo(
        this.postPermitChecksQuestions,
        localizedStrings.postPermitChecks,
      )
    }
    if (
      this.authorisedPersonQuestions.questions.find((questionGroup) =>
        questionGroup.data.find((q) => q.id === questionId),
      )
    ) {
      return getInfo(
        this.authorisedPersonQuestions,
        localizedStrings.authorisedPerson,
      )
    }
    return null
  }

  public hasCountdownQuestion() {
    return (
      this.permitHolderQuestions?.questions.some((questionGroup) =>
        questionGroup.data.some(
          (q) => q.questionType === 'CONFIGURE_POST_PERMIT_CHECKS_COUNTDOWN',
        ),
      ) ||
      this.authorisedPersonQuestions?.questions.some((questionGroup) =>
        questionGroup.data.some(
          (q) => q.questionType === 'CONFIGURE_POST_PERMIT_CHECKS_COUNTDOWN',
        ),
      ) ||
      this.approvals?.data.some((approval) =>
        approval.approvalQuestions?.questions.some((questionGroup) =>
          questionGroup.data.some(
            (q) => q.questionType === 'CONFIGURE_POST_PERMIT_CHECKS_COUNTDOWN',
          ),
        ),
      )
    )
  }
}
