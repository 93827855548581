import { ParsedUrlQuery } from 'querystring'

type RouteInfo = {
  pathname: string
  query: ParsedUrlQuery
}

export const encodeReturnUrl = (routeInfo: RouteInfo): string => {
  return Buffer.from(JSON.stringify(routeInfo)).toString('base64')
}

export const decodeReturnUrl = (returnUrl: string): RouteInfo | null => {
  try {
    return JSON.parse(Buffer.from(returnUrl, 'base64').toString())
  } catch (e) {
    console.error('Failed to parse return URL:', e)
    return null
  }
}
