// The order of the permissions in this list controls the order
// in which they're shown in the Permissions tab for Auth Groups
// You can change the display order by moving the permissions in this list
export const ALL_PERMISSION_ASSIGNMENT_TYPES = [
  // Permit permissions
  'PERMIT_INITIATION',
  'PERMIT_REVIEW_EXTENSION',
  'PERMIT_APPROVE',
  'PERMIT_CANCEL',
  'REVIEW_PERMIT_TRANSFER_REQUEST',
  'PERMIT_APPROVE_SUSPENSION_RESUME_REQUEST',
  'PERMIT_DO_FINAL_SIGN_OFF',
  'BRIEFED_ON_TEMPLATE_VERSION_ROLE',
  'MANAGE_PERMIT_PROJECT_CONFIGURATION',

  // Checklist permissions
  'CHECKLIST_INITIATION',

  // Register permissions
  'ADD_REGISTER_ITEM',
  'UPDATE_REGISTER_ITEM',
  'UPLOAD_REGISTER_ITEM',
  'DOWNLOAD_REGISTER_ITEM',
  'ASSIGN_TO_USER_PROPERTY',

  // Task permissions
  'ASSUME_TASK_ROLE',

  // Auth Group permissions
  'MANAGE_AUTH_GROUP_MEMBERS',
] as const

export type PermissionAssignmentType =
  typeof ALL_PERMISSION_ASSIGNMENT_TYPES[number]

export const ALL_PERMISSION_ENTITY_TYPES = [
  'ORGANISATION',
  'PROJECT',
  'PERMIT_TEMPLATE',
  'CHECKLIST_TEMPLATE',
  'REGISTER',
  'TASK_TEMPLATE',
] as const

export type PermissionEntityType = typeof ALL_PERMISSION_ENTITY_TYPES[number]

export type IsTemplatePartOfProject = 'YES' | 'NO' | 'NOT_APPLICABLE'

export type TemplateResult = {
  id: number
  currentVersion: {
    id: number
  }
  organisationId: number
  organisationName: string
  name: string
  description: string
  createdOn: string
  modifiedOn: string
  timezone: string
}
