import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import {
  taskTemplateKeys,
  RequestTypes,
} from '@core/react-query/features/tasks/templates'
import { TaskTemplateRole } from '@core/entities/task/TaskTemplateRole'

export const useUpdateTaskTemplateRole = (
  options?: UseMutationOptions<
    TaskTemplateRole,
    string,
    RequestTypes['updateTaskTemplateRole']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...taskTemplateKeys.updateTaskTemplateRole,
    ...options,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: taskTemplateKeys.getTaskTemplateRoles({
          templateId: variables.templateId,
          versionId: variables.versionId,
        }).queryKey,
      })
    },
  })
}
