import { useQuery } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { UseQueryOptions } from '@tanstack/react-query/src/types'
import { taskTemplateKeys } from '../taskTemplateKeys'
import { ITaskTemplate, TaskTemplate } from '@core/entities/task/TaskTemplate'

const templateTransform = (response: ITaskTemplate) =>
  TaskTemplate.new(response)

export const useGetTaskTemplate = (
  payload: RequestTypes['getTaskTemplate'],
  options?: UseQueryOptions<ITaskTemplate, string, TaskTemplate>,
) => {
  return useQuery({
    select: templateTransform,
    ...taskTemplateKeys.getTaskTemplate(payload),
    ...options,
  })
}
