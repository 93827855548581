import { Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import {
  ITaskTemplateStep,
  TaskTemplateStep,
} from '@core/entities/task/TaskTemplateStep'
import { ITaskTemplateStepsWrapper } from './ITaskTemplateStepsWrapper'

/**
 * The `TaskTemplateStepsWrapper` class and `ITaskTemplateStepsWrapper` interface
 * are needed to ensure that `class-transformer` can properly transform the
 * nested `taskSteps` array into instances of `TaskTemplateStep` using the `@Type` decorator.
 * These are used exclusively in the `TaskTemplateVersion` class.
 */
export class TaskTemplateStepsWrapper extends Entity<ITaskTemplateStepsWrapper> {
  @Type(() => TaskTemplateStep)
  taskSteps: Array<TaskTemplateStep>

  public toServer(): Array<Omit<ITaskTemplateStep, 'role'>> {
    return this.taskSteps.map((step) => {
      return step.toServer()
    })
  }
}
