import { Entity } from '@core/entities/entity'
import { plainToInstance } from 'class-transformer'
import { Palette } from '@core/theme/color-palette-siteassist'
import { ITaskTemplateProperties } from './ITaskTemplateProperties'
import { TaskTemplatePriorityType } from './types'

export type DueDateConfig = {
  optional: boolean
  prioritySetsTheDueDate: boolean
  prioritySetsTheDueDateConfig: {
    defaultPriorityValues: Array<{
      priority: TaskTemplatePriorityType
      maxDurationSeconds: number
    }>
    excludeWeekends: boolean
    allowDefaultsToBeOverridden: boolean
  }
}

export class TaskTemplateProperties extends Entity<ITaskTemplateProperties> {
  priorityConfig: {
    defaultValue: TaskTemplatePriorityType
  }

  dueDateConfig: DueDateConfig

  attachmentsConfig: {
    optional: boolean
  }

  public static new(payload: unknown): TaskTemplateProperties {
    const entity = plainToInstance(TaskTemplateProperties, payload)

    return entity
  }

  public static getColorForPriority(
    priority: TaskTemplatePriorityType,
  ): string {
    switch (priority) {
      case 'LOW':
        return Palette.Green[400]
      case 'MEDIUM':
        return Palette.Yellow[500]
      case 'HIGH':
        return Palette.Orange[500]
      case 'CRITICAL':
        return Palette.Red[500]
      default:
        return Palette.Green[400]
    }
  }

  public getPriorityOptions(): Array<{
    priority: TaskTemplatePriorityType
    maxDurationSeconds: number
  }> {
    return this.dueDateConfig.prioritySetsTheDueDateConfig.defaultPriorityValues
  }

  public getDueDateRequired(): boolean {
    return !this.dueDateConfig.optional
  }

  public getDueDatePrioritySetsTheDueDate(): boolean {
    return this.dueDateConfig.prioritySetsTheDueDate
  }
}
