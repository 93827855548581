import React, { useMemo } from 'react'
import { useIntercom } from 'react-use-intercom'
import { Button, Sidebar, SidebarLinkOption } from '@common/material'
import { PageUrls } from '@core/page-urls'
import {
  Article as ArticleFilled,
  ArticleOutlined,
  BarChartOutlined,
  Business as BussinessFilled,
  BusinessOutlined,
  ChevronLeft,
  CloudQueueOutlined,
  Dashboard as DashboardFilled,
  DashboardOutlined,
  FactCheck as FactCheckFilled,
  FactCheckOutlined,
  Folder as FolderOpenFilled,
  FolderCopy as FolderCopyFilled,
  FolderCopyOutlined,
  FolderOpenOutlined,
  Groups as GroupsFilled,
  GroupsOutlined,
  HelpOutline,
  InsertDriveFile as InsertDriveFileFilled,
  InsertDriveFileOutlined,
  Inventory2,
  Inventory2Outlined,
  Label,
  Logout,
  Map as MapFilled,
  MapOutlined,
  PeopleAlt as PeopleAltFilled,
  PeopleAltOutlined,
  Place as PlaceFilled,
  PlaceOutlined,
  Settings,
  SettingsOutlined,
  ViewTimeline,
  ViewTimelineOutlined,
  AssignmentOutlined,
  Assignment,
} from '@mui/icons-material'
import { OrganisationMenu } from './OrganisationMenu'
import { NavigationAwareSidebarProps } from './types'
import { useDeepCompareMemo } from 'use-deep-compare'
import { NestedLinksHeader } from './NestedLinksHeader'
import { useNavigationContext } from '@common/hooks'
import { useRouter } from 'next/router'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import { useGetProject } from '@core/react-query/features/projects/project/hooks/useGetProject'
import { useGetOrganisation } from '@core/react-query/features/organisations/organisation/hooks/useGetOrganisation'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { useGetBusinessUnit } from '@core/react-query/features/businessUnits'
import { SidebarProjectPicker } from './SidebarProjectPicker'
import { localizedStrings } from '@core/strings'
import { SidebarNewButton } from './SidebarNewButton'
import { useGetProjectPlans } from '@core/react-query/features/projects/plans'
import { Box, Flex, Text } from '@fundamentals'

const NavigationAwareSidebar: React.FC<NavigationAwareSidebarProps> = ({
  hideSecondarySidebar,
}) => {
  const router = useRouter()
  const { flags } = useFeatureFlags([
    'project_documents',
    'organisation_companies',
    'web_tasks',
  ])
  const {
    organisationId,
    projectId,
    businessUnitId,
    inSupportContext,
    dashboardId,
    inProjectSettingsContext,
    inOrganisationSettingsContext,
    asPathWithoutQuery,
  } = useNavigationContext()
  const { data: businessUnit } = useGetBusinessUnit({ businessUnitId })
  const { show, shutdown } = useIntercom()
  const { data: organisation } = useGetOrganisation({ organisationId })
  const { data: project } = useGetProject({ projectId: projectId })
  const { data: profile } = useGetProfile()
  const { data: plans } = useGetProjectPlans({ projectId })

  const sidebarMainLinks = useDeepCompareMemo(() => {
    if (projectId) {
      const projectLinks: SidebarLinkOption[] = [
        {
          title: localizedStrings.dashboard,
          href: PageUrls.projectOverview({ organisationId, projectId }),
          icon: <DashboardOutlined />,
          selectedIcon: <DashboardFilled />,
        },
      ]

      if (flags.web_tasks) {
        projectLinks.push({
          title: localizedStrings.tasks,
          href: PageUrls.projectTasks({ organisationId, projectId }),
          icon: <AssignmentOutlined />,
          selectedIcon: <Assignment />,
        })
      }

      projectLinks.push({
        title: localizedStrings.analytics,
        href: PageUrls.projectAnalytics({ organisationId, projectId }),
        icon: <BarChartOutlined />,
        selectedIcon: <BarChartOutlined />,
      })

      projectLinks.push({
        title: localizedStrings.users,
        href: PageUrls.projectUsers(organisationId, projectId),
        icon: <PeopleAltOutlined />,
        selectedIcon: <PeopleAltFilled />,
      })

      if (project?.mapBoxConfigResult?.mapBoxStyleUrl) {
        projectLinks.push({
          title: localizedStrings.map,
          href: PageUrls.projectMap({ organisationId, projectId }),
          icon: <PlaceOutlined />,
          selectedIcon: <PlaceFilled />,
        })
      }

      plans?.length > 0 &&
        projectLinks.push({
          title: localizedStrings.plans,
          href: PageUrls.projectViewPlans(organisationId, projectId),
          icon: <MapOutlined />,
          selectedIcon: <MapFilled />,
        })

      projectLinks.push({
        title: localizedStrings.permits,
        href: PageUrls.projectPermits(organisationId, projectId),
        icon: <ArticleOutlined />,
        selectedIcon: <ArticleFilled />,
      })

      projectLinks.push({
        title: localizedStrings.checklists,
        href: PageUrls.projectChecklists({ organisationId, projectId }),
        icon: <FactCheckOutlined />,
        selectedIcon: <FactCheckFilled />,
      })

      projectLinks.push({
        title: localizedStrings.registers,
        href: PageUrls.projectRegisters(organisationId, projectId),
        icon: <FolderCopyOutlined />,
        selectedIcon: <FolderCopyFilled />,
      })

      if (project?.canViewTimeline()) {
        projectLinks.push({
          title: localizedStrings.timeline,
          href: PageUrls.projectTimeline(organisationId, projectId),
          icon: <ViewTimelineOutlined />,
          selectedIcon: <ViewTimeline />,
        })
      }

      projectLinks.push({
        title: localizedStrings.siteBox,
        href: PageUrls.projectSiteBox({ organisationId, projectId }),
        icon: <Inventory2Outlined />,
        selectedIcon: <Inventory2 />,
      })

      projectLinks.push({
        title: localizedStrings.settings,
        href: PageUrls.projectSettings({ organisationId, projectId }),
        icon: <SettingsOutlined />,
        selectedIcon: <Settings />,
      })

      return projectLinks
    }

    if (inSupportContext) {
      const links: SidebarLinkOption[] = []
      links.push({
        title: localizedStrings.organisations,
        href: PageUrls.adminOrganisations,
        icon: <BusinessOutlined />,
        selectedIcon: <BussinessFilled />,
      })
      links.push({
        title: localizedStrings.users,
        href: PageUrls.adminUsers,
        icon: <PeopleAltOutlined />,
        selectedIcon: <PeopleAltFilled />,
      })
      links.push({
        title: localizedStrings.permits,
        href: PageUrls.adminPermits,
        icon: <ArticleOutlined />,
        selectedIcon: <ArticleFilled />,
      })
      links.push({
        title: localizedStrings.analytics,
        href: PageUrls.adminAnalytics,
        icon: <BarChartOutlined />,
        selectedIcon: <BarChartOutlined />,
      })
      links.push({
        title: 'PDF Demo',
        href: '/poc/pdf-demo',
        icon: <InsertDriveFileOutlined />,
        selectedIcon: <InsertDriveFileFilled />,
      })
      if (profile.isSuperUser()) {
        links.push({
          title: 'Kafka',
          href: '/support/kafka',
          icon: <CloudQueueOutlined />,
          selectedIcon: <CloudQueueOutlined />,
        })
      }
      links.push({
        title: 'Labelling',
        href: '/support/prodigy-labelling',
        icon: <Label />,
        selectedIcon: <Label />,
      })
      links.push({
        title: 'Mapbox WSM test',
        href: '/support/mapbox-wsm',
        icon: <MapOutlined />,
        selectedIcon: <MapFilled />,
      })
      links.push({
        title: 'KMZ POC',
        href: '/support/kmz-poc',
        icon: <MapOutlined />,
        selectedIcon: <MapFilled />,
      })
      return links
    }

    if (organisationId) {
      const links: SidebarLinkOption[] = []

      links.push({
        title: localizedStrings.dashboard,
        href: PageUrls.organisation(organisationId),
        icon: <DashboardOutlined />,
        selectedIcon: <DashboardFilled />,
      })

      if (flags.web_tasks) {
        links.push({
          title: localizedStrings.tasks,
          href: PageUrls.tasks(organisationId),
          icon: <AssignmentOutlined />,
          selectedIcon: <Assignment />,
        })
      }

      links.push({
        title: localizedStrings.analytics,
        href: PageUrls.organisationAnalytics({
          organisationId: organisationId,
        }),
        icon: <BarChartOutlined />,
        selectedIcon: <BarChartOutlined />,
      })

      if (organisation?.canSeeBusinessUnits()) {
        links.push({
          title: localizedStrings.businessUnits,
          href: PageUrls.businessUnits(organisationId),
          icon: <GroupsOutlined />,
          selectedIcon: <GroupsFilled />,
          dataTest: 'jsBusinessUnits',
        })
      }

      links.push({
        title: localizedStrings.projects,
        href: PageUrls.projects(organisationId),
        icon: <FolderOpenOutlined />,
        selectedIcon: <FolderOpenFilled />,
        dataTest: 'jsProjects',
      })

      if (organisation?.canSeeDetailsAndUsers()) {
        links.push({
          title: localizedStrings.users,
          href: PageUrls.organisationUsers(organisationId),
          icon: <PeopleAltOutlined />,
          selectedIcon: <PeopleAltFilled />,
          dataTest: 'organisation-users',
        })
      }

      links.push({
        title: localizedStrings.permits,
        href: PageUrls.permits(organisationId),
        icon: <ArticleOutlined />,
        selectedIcon: <ArticleFilled />,
        dataTest: 'jsPermits',
      })

      links.push({
        title: localizedStrings.checklists,
        href: PageUrls.checklists(organisationId),
        icon: <FactCheckOutlined />,
        selectedIcon: <FactCheckFilled />,
        dataTest: 'jsChecklists',
      })

      links.push({
        title: localizedStrings.registers,
        href: PageUrls.registers(organisationId),
        icon: <FolderCopyOutlined />,
        selectedIcon: <FolderCopyFilled />,
        dataTest: 'jsRegisters',
      })

      if (organisation?.canViewTimeline()) {
        links.push({
          title: localizedStrings.timeline,
          href: PageUrls.organisationTimeline({ organisationId }),
          icon: <ViewTimelineOutlined />,
          selectedIcon: <ViewTimeline />,
        })
      }

      links.push({
        title: localizedStrings.settings,
        href: PageUrls.organisationSettings({ organisationId }),
        icon: <SettingsOutlined />,
        selectedIcon: <Settings />,
      })

      return links
    }
  }, [
    flags,
    organisation,
    project,
    organisationId,
    inSupportContext,
    profile,
    plans,
  ])

  const sidebarBottomLinks = useMemo(() => {
    return [
      {
        title: localizedStrings.help,
        onClick: show,
        icon: <HelpOutline />,
      },
      {
        title: localizedStrings.logout,
        dataTest: 'jsLogout',
        onClick: () => {
          API.logout()
          shutdown()
        },
        icon: <Logout />,
      },
    ]
  }, [show, shutdown])

  const sidebarNestedLinks = useDeepCompareMemo(() => {
    if (dashboardId) {
      return null
    }

    if (inOrganisationSettingsContext) {
      const nestedSidebarLinks = []

      nestedSidebarLinks.push({
        title: localizedStrings.general,
        href: PageUrls.organisationGeneralSettings({ organisationId }),
      })

      if (organisation?.canSeeAuthorisationGroups()) {
        nestedSidebarLinks.push({
          title: localizedStrings.authorisationGroups,
          href: PageUrls.organisationAuthorisationGroupList({
            organisationId,
          }),
        })
      }

      if (flags.organisation_companies) {
        nestedSidebarLinks.push({
          title: localizedStrings.companies,
          href: PageUrls.organisationCompanySettings({ organisationId }),
        })
      }

      nestedSidebarLinks.push({
        title: localizedStrings.permitTemplates,
        href: PageUrls.organisationPermitTemplates({ organisationId }),
      })

      nestedSidebarLinks.push({
        title: localizedStrings.checklistTemplates,
        href: PageUrls.organisationChecklistTemplates({ organisationId }),
      })

      nestedSidebarLinks.push({
        title: localizedStrings.registerTemplates,
        href: PageUrls.organisationRegisterTemplates({ organisationId }),
      })

      if (flags.web_tasks) {
        nestedSidebarLinks.push({
          title: localizedStrings.taskTemplates,
          href: PageUrls.organisationTaskTemplates({ organisationId }),
        })
      }

      if (organisation?.canSeeLinkedOrganizations(profile?.role)) {
        nestedSidebarLinks.push({
          title: localizedStrings.linkedOrganisations,
          href: PageUrls.linkedOrganisations(organisationId),
          dataTest: 'jsLinkedOrganisations',
        })
      }

      nestedSidebarLinks.push({
        title: localizedStrings.tags,
        href: PageUrls.organisationProcessTags({ organisationId }),
      })

      if (organisation?.canSeeDeveloperSection()) {
        nestedSidebarLinks.push({
          title: localizedStrings.developer,
          href: PageUrls.developerSection({ organisationId }),
        })
      }

      return nestedSidebarLinks
    }

    if (inProjectSettingsContext) {
      const nestedSidebarLinks = []

      nestedSidebarLinks.push({
        title: localizedStrings.general,
        href: PageUrls.projectSettingsGeneral({
          organisationId,
          projectId,
        }),
      })

      if (project?.canSeeAuthorisationGroups()) {
        nestedSidebarLinks.push({
          title: localizedStrings.authorisationGroups,
          href: PageUrls.projectAuthorisationGroupList({
            organisationId,
            projectId,
          }),
        })
      }

      nestedSidebarLinks.push({
        title: localizedStrings.permitTemplates,
        href: PageUrls.projectPermitTemplates({ organisationId, projectId }),
      })

      nestedSidebarLinks.push({
        title: localizedStrings.checklistTemplates,
        href: PageUrls.projectChecklistTemplates({ organisationId, projectId }),
      })

      if (flags.web_tasks) {
        nestedSidebarLinks.push({
          title: localizedStrings.taskTemplates,
          href: PageUrls.projectTaskTemplates({ organisationId, projectId }),
        })
      }

      nestedSidebarLinks.push({
        title: localizedStrings.siteLocations,
        href: PageUrls.projectLocations({ organisationId, projectId }),
      })

      nestedSidebarLinks.push({
        title: localizedStrings.map,
        href: PageUrls.projectMapSettings({ organisationId, projectId }),
      })

      return nestedSidebarLinks
    }

    if (projectId) {
      return null
    }

    if (businessUnitId) {
      const nestedSidebarLinks = []
      nestedSidebarLinks.push({
        title: localizedStrings.overview,
        href: PageUrls.businessUnitOverview({
          id: organisationId,
          businessUnitId: businessUnitId,
        }),
      })
      nestedSidebarLinks.push({
        title: localizedStrings.analytics,
        href: PageUrls.businessUnitAnalytics({
          organisationId,
          businessUnitId,
        }),
      })
      nestedSidebarLinks.push({
        title: localizedStrings.projects,
        href: PageUrls.businessUnitProjects({
          id: organisationId,
          businessUnitId: businessUnitId,
        }),
      })
      nestedSidebarLinks.push({
        title: localizedStrings.permits,
        href: PageUrls.businessUnitPermits({
          id: organisationId,
          businessUnitId: businessUnitId,
        }),
      })
      nestedSidebarLinks.push({
        title: localizedStrings.checklists,
        href: PageUrls.businessUnitChecklists({
          id: organisationId,
          businessUnitId: businessUnitId,
        }),
      })
      return nestedSidebarLinks
    }
  }, [
    flags,
    project,
    organisationId,
    projectId,
    businessUnitId,
    inProjectSettingsContext,
  ])

  const collapsePrimarySidebar =
    !!sidebarNestedLinks ||
    !!dashboardId ||
    asPathWithoutQuery?.includes('severn-trent-usage')

  const sidebarHeader = useDeepCompareMemo(() => {
    if (projectId) {
      return (
        <>
          {!collapsePrimarySidebar && (
            <Button
              sx={{ mb: 1, mt: -1 }}
              size='small'
              startIcon={<ChevronLeft />}
              onClick={() => {
                router.push(PageUrls.organisation(organisationId))
              }}
            >
              {localizedStrings.backToOrganisation}
            </Button>
          )}
          <SidebarProjectPicker
            organisationId={organisationId}
            projectId={projectId}
            collapse={collapsePrimarySidebar}
          />
          <SidebarNewButton
            organisationId={organisationId}
            projectId={projectId}
            collapsed={collapsePrimarySidebar}
          />
        </>
      )
    }

    if (organisationId && !inSupportContext) {
      return (
        <>
          <OrganisationMenu
            organisationId={organisationId}
            collapse={collapsePrimarySidebar}
          />
          <SidebarNewButton
            organisationId={organisationId}
            projectId={projectId}
            collapsed={collapsePrimarySidebar}
          />
        </>
      )
    }
  }, [organisationId, projectId, sidebarNestedLinks, flags])

  const sidebarNestedLinkHeader = useDeepCompareMemo(() => {
    if (inProjectSettingsContext) {
      return (
        <Flex flexDirection={'column'}>
          <Box p={3} alignItems='center' justifyContent='space-between'>
            <Text variant='h5' fontWeight={600} fontSize={20}>
              {localizedStrings.settings}
            </Text>
          </Box>
          <Button
            sx={{ alignSelf: 'flex-start', ml: 2, mt: -2 }}
            size='small'
            startIcon={<ChevronLeft />}
            onClick={() => {
              router.push(
                PageUrls.projectOverview({ organisationId, projectId }),
              )
            }}
          >
            {localizedStrings.backToProject}
          </Button>
        </Flex>
      )
    }

    if (inOrganisationSettingsContext) {
      return (
        <Flex flexDirection={'column'}>
          <Box p={3} alignItems='center' justifyContent='space-between'>
            <Text variant='h5' fontWeight={600} fontSize={20}>
              {localizedStrings.settings}
            </Text>
          </Box>
          <Button
            sx={{ alignSelf: 'flex-start', ml: 2, mt: -2 }}
            size='small'
            startIcon={<ChevronLeft />}
            onClick={() => {
              router.push(PageUrls.organisation(organisationId))
            }}
          >
            {localizedStrings.backToOrganisation}
          </Button>
        </Flex>
      )
    }

    if (businessUnitId) {
      return (
        <NestedLinksHeader
          href={PageUrls.businessUnitEdit({
            id: organisationId,
            businessUnitId: businessUnitId,
          })}
          label={businessUnit?.name}
        />
      )
    }
  }, [businessUnitId, projectId, project, businessUnit])

  return (
    <Sidebar
      profile={profile}
      primarySidebar={{
        header: sidebarHeader,
        mainLinks: sidebarMainLinks,
        bottomLinks: sidebarBottomLinks,
      }}
      collapsePrimarySidebar={collapsePrimarySidebar}
      nestedSidebar={
        sidebarNestedLinks &&
        !hideSecondarySidebar && {
          mainLinks: sidebarNestedLinks,
          header: sidebarNestedLinkHeader,
        }
      }
    />
  )
}

export { NavigationAwareSidebar }
