import { Entity } from '@core/entities/entity'
import {
  IsTemplatePartOfProject,
  PermissionEntityType,
  TemplateResult,
} from '@core/entities/Groups/GroupsPermissions/types'
import { plainToInstance, Type } from 'class-transformer'
import { IGroupPermission } from '@core/entities/Groups/GroupsPermissions/IGroupPermission'
import { Register } from '@core/entities/register/Register'
import { Project } from '@core/entities/project'
import { GroupPermissionAssignment } from '@core/entities/Groups/GroupsPermissions/GroupPermissionAssignment'

export class GroupPermission extends Entity<IGroupPermission> {
  type: PermissionEntityType

  @Type(() => Register)
  registerResult?: Register

  @Type(() => Project)
  projectResult?: Project

  templateResult?: TemplateResult

  organisationResult?: {
    id: number
    name: string
    logoId: number
    status: string
    isVisibleTestOrganisation: boolean
    timezone: string
  }

  @Type(() => GroupPermissionAssignment)
  permissions: Array<GroupPermissionAssignment>

  isTemplatePartOfProject: IsTemplatePartOfProject

  public getReferenceObjectId(): number {
    switch (this.type) {
      case 'PERMIT_TEMPLATE':
        return this.templateResult.id
      case 'REGISTER':
        return this.registerResult.id
      case 'PROJECT':
        return this.projectResult.id
      case 'ORGANISATION':
        return this.organisationResult.id
      case 'CHECKLIST_TEMPLATE':
        return this.templateResult.id
      case 'TASK_TEMPLATE':
        return this.templateResult.id
      default:
        return null
    }
  }

  public getEntityName(): string {
    switch (this.type) {
      case 'PERMIT_TEMPLATE':
        return this.templateResult.name
      case 'REGISTER':
        return this.registerResult.name
      case 'PROJECT':
        return this.projectResult.name
      case 'ORGANISATION':
        return this.organisationResult.name
      case 'CHECKLIST_TEMPLATE':
        return this.templateResult.name
      case 'TASK_TEMPLATE':
        return this.templateResult.name
      default:
        return null
    }
  }

  public static new(payload: unknown): GroupPermission {
    const entity = plainToInstance(GroupPermission, payload)

    return entity
  }
}
