import { TaskTemplate } from '@core/entities/task/TaskTemplate'
import {
  taskTemplateKeys,
  RequestTypes,
} from '@core/react-query/features/tasks/templates'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

export const useUpdateTaskTemplate = (
  options?: UseMutationOptions<
    TaskTemplate,
    string,
    RequestTypes['updateTaskTemplate']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...taskTemplateKeys.updateTaskTemplate,
    ...options,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: taskTemplateKeys.getTaskTemplate({
          templateId: variables.id,
        }).queryKey,
      })
    },
  })
}
