import { localizedStrings } from '@core/strings'
import {
  SelectFilterInternalArguments,
  SelectFilterValue,
  useSelectFilter,
} from './fundamentals'
import { TaskTemplateStatus } from '@core/entities/task/TaskTemplate/types'
import { FilterHookArgumentsBase } from '@core/providers/filters'
import { useGetTaskTemplates } from '@core/react-query/features/tasks/templates'
import { useMemo } from 'react'

type TaskTemplateFilterHookArgs<Multiple extends boolean | undefined> =
  FilterHookArgumentsBase<SelectFilterValue<number, Multiple>> & {
    organisationId: number
    projectIds?: number[]
    status?: TaskTemplateStatus
    multiple?: Multiple
    whitelist?: number[]
  }

export const useTaskTemplatesFilter: <Multiple extends boolean | undefined>(
  arg: TaskTemplateFilterHookArgs<Multiple>,
) => SelectFilterInternalArguments<number, Multiple> = ({
  organisationId,
  projectIds,
  whitelist,
  ...args
}) => {
  const { data: taskTemplates } = useGetTaskTemplates({
    organisationId,
    projectIds,
    page: 1,
    size: 1000,
  })

  const getValueToLabel = (v, list) => {
    return list?.find((t) => t.id === v)?.name
  }

  const filteredTemplates = useMemo(() => {
    if (whitelist?.length) {
      return taskTemplates?.content.filter((template) =>
        whitelist?.includes(template.id),
      )
    }

    return taskTemplates?.content
  }, [taskTemplates, whitelist])

  return useSelectFilter({
    ...args,
    options: filteredTemplates?.map((t) => t?.id) || [],
    valueToLabel: (v) => getValueToLabel(v, filteredTemplates),
    nothingSelectedText: localizedStrings.allTasks,
  })
}
