import { localizedStrings } from '@core/strings'
import React from 'react'
import { useAvailableTaskTemplates } from '@core/react-query/features/tasks/templates'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { Section } from '@common/Section'
import { Text } from '@fundamentals'
import { TaskTemplate } from '@core/entities/task/TaskTemplate'
import { TemplatePickerWrapper } from '@elements/Pickers/TemplatePickerWrapper'

export type TaskTemplatePickerProps = TextFieldProps & {
  organisationId: number
  projectId: number
  value: TaskTemplate
  onChange?: (template: TaskTemplate) => void
  readOnly?: boolean
}

const TaskTemplatePicker: React.FC<TaskTemplatePickerProps> = ({
  organisationId,
  projectId,
  value,
  onChange,
  readOnly,
  ...props
}) => {
  const { data } = useAvailableTaskTemplates({
    projectId,
  })

  const findTemplateById = (id) => data?.templates?.find((t) => t.id === id)

  if (readOnly) {
    const templateName = data?.templates?.find(
      (template) => template.id === value?.id,
    )?.name

    return (
      <Section sx={props?.sx} label={localizedStrings.taskTemplate}>
        <Text>{templateName}</Text>
      </Section>
    )
  }

  return (
    <TemplatePickerWrapper
      label={localizedStrings.taskTemplate}
      availableTemplates={data}
      organisationId={organisationId}
      projectId={projectId}
      entityType='TASK_TEMPLATE'
      value={value}
      onChange={(templateId) => {
        const template = findTemplateById(templateId)
        onChange(template)
      }}
      {...props}
    />
  )
}

export { TaskTemplatePicker }
