import React from 'react'
import { Text } from '@fundamentals'
import { TimelineBlockBase } from '@common/Timeline'
import { Box, Flex } from '@fundamentals'

type ValueFormatter = (value: any) => string
type ValueComposer = (obj: any) => any

type TrackedField = {
  path: string[] // e.g. ['user', 'name'] or ['status']
  displayName?: string // Optional custom display name
  formatValue?: ValueFormatter // Optional custom formatter for the value
  composeValue?: ValueComposer // Optional function to compose value from multiple fields
  composePaths?: string[][] // Optional array of paths needed for composition
}

type ComponentProps = {
  title: string
  preValues: Record<string, any>
  postValues: Record<string, any>
  trackedFields: TrackedField[] // Array of fields to track
}

const formatFieldName = (field: TrackedField): string => {
  if (field.displayName) return field.displayName

  return field.path
    .map((key) =>
      key
        .replace(/([A-Z])/g, ' $1')
        .replace(/_/g, ' ')
        .replace(/^\w/, (c) => c.toUpperCase())
        .trim(),
    )
    .join(' → ')
}

const defaultFormatValue = (value: any): string => {
  if (value === null || value === undefined) {
    return 'None'
  }

  if (Array.isArray(value)) {
    if (value.length === 0) return '[]'
    return `[${value.map((item) => defaultFormatValue(item)).join(', ')}]`
  }

  if (typeof value === 'string' && value.trim() === '') {
    return '(empty string)'
  }

  return value.toString()
}

const getValueFromPath = (obj: any, path: string[]): any => {
  return path.reduce(
    (current, key) =>
      current && typeof current === 'object' ? current[key] : undefined,
    obj,
  )
}

const getComposedValue = (obj: any, field: TrackedField): any => {
  if (field.composeValue && field.composePaths) {
    // Get all required values for composition
    const values = field.composePaths.map((path) => getValueFromPath(obj, path))
    // Pass the values to the compose function
    return field.composeValue(values)
  }

  return getValueFromPath(obj, field.path)
}

interface DiffResult {
  field: TrackedField
  preValue: any
  postValue: any
}

const findTrackedChanges = (
  preObj: any,
  postObj: any,
  trackedFields: TrackedField[],
): DiffResult[] => {
  return trackedFields
    .map((field) => {
      const preValue = getComposedValue(preObj, field)
      const postValue = getComposedValue(postObj, field)

      if (JSON.stringify(preValue) !== JSON.stringify(postValue)) {
        return {
          field,
          preValue,
          postValue,
        }
      }
      return null
    })
    .filter((result): result is DiffResult => result !== null)
}

export const TimelineDiffBlock: React.FC<ComponentProps> = ({
  title,
  preValues,
  postValues,
  trackedFields,
}) => {
  const changes = findTrackedChanges(preValues, postValues, trackedFields)

  if (changes.length === 0) {
    return (
      <TimelineBlockBase title={title}>
        <Text variant='body2' color='text.secondary'>
          No changes detected
        </Text>
      </TimelineBlockBase>
    )
  }

  return (
    <TimelineBlockBase title={title}>
      {changes.map((change, index) => {
        const formatValue = change.field.formatValue || defaultFormatValue

        return (
          <Box key={index} mb={2}>
            <Text variant='body2' fontWeight='bold' mb={1}>
              {formatFieldName(change.field)}
            </Text>
            <Flex alignItems='flex-start'>
              <Text
                variant='body2'
                color='text.primary'
                sx={{ maxWidth: '40%' }}
              >
                {formatValue(change.preValue)}
              </Text>
              <Text variant='body2' color='text.secondary' mx={2}>
                →
              </Text>
              <Text
                variant='body2'
                color='text.primary'
                sx={{ maxWidth: '40%' }}
              >
                {formatValue(change.postValue)}
              </Text>
            </Flex>
          </Box>
        )
      })}
    </TimelineBlockBase>
  )
}
