import React from 'react'
import { useChecklistTimeline } from '@core/react-query/features/checklists/checklist'
import { Timeline } from '@common/Timeline'

type ChecklistTimelineTabProps = {
  checklistId: number
}

export const ChecklistTimelineTab: React.FC<ChecklistTimelineTabProps> = ({
  checklistId,
}) => {
  const {
    data: timelineData,
    isFetching: checklistTimelineLoading,
    error: checklistTimelineError,
  } = useChecklistTimeline({ checklistId })

  return (
    <>
      <ErrorMessage>{checklistTimelineError}</ErrorMessage>
      <Timeline
        isLoading={checklistTimelineLoading}
        auditResults={timelineData}
        scope='CHECKLIST'
      />
    </>
  )
}
