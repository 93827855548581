import { Exclude, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IProjectPlanPSPDFArea } from './IProjectPlanPSPDFArea'
import { IProjectPlanArea } from '@core/entities/project/ProjectPlanArea'

export const PROJECT_PLAN_AREA_COLORS = [
  {
    hex: '#1976D2',
    rgb: { r: 25, g: 118, b: 210 },
    enum: 'LIGHT_PRIMARY_BLUE',
    name: 'Blue',
  },
  {
    hex: '#6554C0',
    rgb: { r: 101, g: 84, b: 192 },
    enum: 'LIGHT_SECONDARY',
    name: 'Purple',
  },
  {
    hex: '#FF5630',
    rgb: { r: 255, g: 86, b: 48 },
    enum: 'LIGHT_ERROR_MAIN',
    name: 'Red',
  },
  {
    hex: '#FF8B00',
    rgb: { r: 255, g: 139, b: 0 },
    enum: 'LIGHT_WARNING_MAIN',
    name: 'Yellow',
  },
  {
    hex: '#00B8D9',
    rgb: { r: 0, g: 184, b: 217 },
    enum: 'LIGHT_INFO_MAIN',
    name: 'Cyan',
  },
  {
    hex: '#36B37E',
    rgb: { r: 54, g: 179, b: 126 },
    enum: 'LIGHT_SUCCESS_MAIN',
    name: 'Green',
  },
  {
    hex: '#D500F9',
    rgb: { r: 213, g: 0, b: 249 },
    enum: 'DARK_SECONDARY_MAIN',
    name: 'Magenta',
  },
  {
    hex: '#283593',
    rgb: { r: 40, g: 53, b: 147 },
    enum: 'INDIGO_800',
    name: 'Dark Blue',
  },
  {
    hex: '#D81B60',
    rgb: { r: 216, g: 27, b: 96 },
    enum: 'PINK_600',
    name: 'Pink',
  },
  {
    hex: '#00E676',
    rgb: { r: 0, g: 230, b: 118 },
    enum: 'GREEN_A400',
    name: 'Light Green',
  },
  {
    hex: '#33691E',
    rgb: { r: 51, g: 105, b: 30 },
    enum: 'LIGHT_GREEN_900',
    name: 'Dark Green',
  },
]

export type PROJECT_PLAN_AREA_COLOR_ENUMS =
  | 'LIGHT_PRIMARY_BLUE'
  | 'LIGHT_SECONDARY'
  | 'LIGHT_ERROR_MAIN'
  | 'LIGHT_WARNING_MAIN'
  | 'LIGHT_INFO_MAIN'
  | 'LIGHT_SUCCESS_MAIN'
  | 'DARK_SECONDARY_MAIN'
  | 'INDIGO_800'
  | 'PINK_600'
  | 'GREEN_A400'
  | 'LIGHT_GREEN_900'

const PROJECT_PLAN_AREA_OPACITY = {
  selected: 0.8,
  deselected: 0.3,
  default: 0.2,
}

export class ProjectPlanPSPDFArea extends Entity<IProjectPlanPSPDFArea> {
  id: string

  name: string

  bbox: number[]

  points: number[][]

  pageIndex: number

  type: string

  strokeColor: string

  fillColor: string

  opacity: number

  strokeWidth: number

  v: number

  @Exclude({ toPlainOnly: true })
  isSelected: boolean

  public static new(payload: unknown): ProjectPlanPSPDFArea {
    const entity = plainToInstance(ProjectPlanPSPDFArea, payload)

    return entity
  }

  public static newFromProjectPlanArea(
    projectPlanArea: IProjectPlanArea,
    defaultOpacity?: number,
  ): ProjectPlanPSPDFArea {
    const color =
      PROJECT_PLAN_AREA_COLORS.find(
        (color) => color.enum === projectPlanArea.color,
      ) ?? PROJECT_PLAN_AREA_COLORS[0]

    const transformToProjectPlanPSPDFArea = {
      id: projectPlanArea.uuid,
      name: projectPlanArea.name,
      bbox: projectPlanArea.annotationData.bbox,
      points: projectPlanArea.annotationData.points,
      pageIndex: projectPlanArea.annotationData.pageIndex,
      type: `pspdfkit/shape/${projectPlanArea.annotationData.type.toLowerCase()}`,
      strokeColor: color.hex,
      fillColor: color.hex,
      opacity: defaultOpacity
        ? defaultOpacity
        : PROJECT_PLAN_AREA_OPACITY.default,
      strokeWidth: 5,
      v: 1,
      isSelected: false,
    }

    const entity = plainToInstance(
      ProjectPlanPSPDFArea,
      transformToProjectPlanPSPDFArea,
    )

    return entity
  }

  public selectArea(): void {
    this.isSelected = true
    this.opacity = PROJECT_PLAN_AREA_OPACITY.selected
  }

  public deselectArea(): void {
    this.isSelected = false
    this.opacity = PROJECT_PLAN_AREA_OPACITY.deselected
  }

  public toImageAnnotation({
    imageWidthInPixels,
    pageWidthInPoints,
    pageHeightInPoints,
  }: {
    imageWidthInPixels: number
    pageWidthInPoints: number
    pageHeightInPoints: number
  }): ProjectPlanPSPDFArea {
    const heightToWidthRation = pageHeightInPoints / pageWidthInPoints
    const imageHeightInPixels = imageWidthInPixels * heightToWidthRation
    const widthPixelsPerPoint = imageWidthInPixels / pageWidthInPoints
    const heightPixelsPerPoint = imageHeightInPixels / pageHeightInPoints

    if (this.bbox) {
      const [x, y, w, h] = this.bbox
      this.bbox = [
        x * widthPixelsPerPoint,
        y * heightPixelsPerPoint,
        w * widthPixelsPerPoint,
        h * heightPixelsPerPoint,
      ]
    }

    if (this.points) {
      this.points = this.points.map((point) => [
        point[0] * widthPixelsPerPoint,
        point[1] * heightPixelsPerPoint,
      ])
    }
    return this
  }
}
