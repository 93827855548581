import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { TaskTemplateRole } from '@core/entities/task/TaskTemplateRole'
import { TemplateForm } from '@core/entities/template/TemplateForm'
import { TemplateFormAnswers } from '@core/entities/template/TemplateFormAnswers'
import { User } from '@core/entities/user'
import { TaskTemplateStepType } from '@core/entities/task/TaskTemplateStep'
import { ITaskStep } from './ITaskStep'

export class TaskStep extends Entity<ITaskStep> {
  id: string
  type: TaskTemplateStepType
  name: string
  description: string
  @Type(() => TaskTemplateRole)
  role: TaskTemplateRole

  // If the step is not answered -> stepQuestions is set
  @Type(() => TemplateForm)
  stepQuestions?: TemplateForm

  // If the step is answered -> stepAnswers, submittedBy and submittedOn are set
  @Type(() => TemplateFormAnswers)
  stepAnswers?: TemplateFormAnswers
  @Type(() => User)
  submittedBy?: User
  submittedOn?: string

  public static new(payload: unknown): TaskStep {
    const entity = plainToInstance(TaskStep, payload)

    return entity
  }

  public isCreationStep(): boolean {
    return this.type === 'CREATION'
  }

  public isWorkStep(): boolean {
    return this.type === 'WORK'
  }

  public isApprovalStep(): boolean {
    return this.type === 'APPROVAL'
  }
}
