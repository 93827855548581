import { localizedStrings } from '@core/strings'
import React, { useMemo, useRef, useState } from 'react'
import { Divider, MenuItem, TextField } from '@common/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { Box, Flex, Text } from '@fundamentals'
import {
  AvailablePermitsTemplatesWrapper,
  PermitTemplate,
} from '@core/entities/permit/PermitTemplate'

import { Collapse } from '@mui/material'
import { useGetProcessTags } from '@core/react-query/features/processTags/hooks'
import { ProcessTagChip } from '@common/ProcessTagChip'
import {
  AvailableChecklistTemplatesWrapper,
  ChecklistTemplate,
} from '@core/entities/checklist/ChecklistTemplate'
import {
  AvailableTaskTemplatesWrapper,
  TaskTemplate,
} from '@core/entities/task/TaskTemplate'
import { ClickAwayListener } from '@mui/base'
import { ProcessTagEntityTypes } from '@core/react-query/features/processTags'

export type TemplatePickerWrapperProps = TextFieldProps & {
  availableTemplates:
    | AvailablePermitsTemplatesWrapper
    | AvailableChecklistTemplatesWrapper
    | AvailableTaskTemplatesWrapper
  organisationId: number
  projectId: number
  entityType: ProcessTagEntityTypes
  value: PermitTemplate | ChecklistTemplate | TaskTemplate
  onChange?: (templateId: number) => void
  readOnly?: boolean
}

export const TemplatePickerWrapper: React.FC<TemplatePickerWrapperProps> = ({
  availableTemplates,
  organisationId,
  projectId,
  entityType,
  value,
  onChange,
  error,
  readOnly,
  label,
  ...props
}) => {
  const searchInputRef = useRef(null)
  const pickerInputRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [tagsFilter, setTagsFilter] = React.useState<number[]>([])
  const { data: tagsData } = useGetProcessTags(
    {
      organisationId,
      size: 10000,
      projectId,
      entityType,
    },
    { enabled: entityType !== 'TASK_TEMPLATE' }, // TODO: enable this when we have process tags for task templates
  )
  const tags = tagsData?.content || []

  const dataResult = useMemo(() => {
    const filterByTags = (template) => {
      if (tagsFilter?.length) {
        return tagsFilter?.some((tag) =>
          template.processTags?.tags?.find((t) => t.id === tag),
        )
      }
      return template
    }

    const filterBySearch = (template) => {
      if (searchTerm.length) {
        return !!(
          template.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          template.id?.toString().includes(searchTerm) ||
          template.code?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      }
      return template
    }

    const reduceData = (
      template: PermitTemplate | ChecklistTemplate | TaskTemplate,
    ) => ({
      id: template?.id,
      name: template?.name,
      code: template?.code,
      processTags: template?.processTags,
    })

    return {
      templates: availableTemplates?.templates
        ?.map(reduceData)
        ?.filter(filterByTags)
        ?.filter(filterBySearch),
      recent: availableTemplates?.recent
        ?.map(reduceData)
        ?.filter(filterByTags)
        ?.filter(filterBySearch),
    }
  }, [tagsFilter, searchTerm, availableTemplates])

  const resetPickerState = () => {
    setSearchTerm('')
    setTagsFilter([])
    onChange(null)
  }

  const handleSelect = (templateId: number) => {
    onChange(templateId)
    setOpen(false)
  }

  const templates = dataResult?.templates || []
  const recent = dataResult?.recent

  return (
    <TextField
      data-test='select-template-dropdown-btn'
      ref={pickerInputRef}
      label={label}
      required
      select
      value={value?.id}
      onClick={() => {
        if (!readOnly) {
          setOpen(true)
          value?.id && resetPickerState()
        }
      }}
      SelectProps={{
        displayEmpty: !!value?.name || open,
        renderValue: () => value?.name || '',
        open,
      }}
      InputLabelProps={{ shrink: !!value?.name || open }}
      FormHelperTextProps={{ error, sx: { pt: 0.5, pb: 2 } }}
      error={error}
      {...props}
    >
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box>
          <>
            <MenuItem
              onClickCapture={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              onKeyDown={(e) => e.stopPropagation()}
              disableRipple
              sx={{
                '&.Mui-focusVisible, &:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <TextField
                autoFocus
                inputRef={searchInputRef}
                label={localizedStrings.search}
                fullWidth
                size='small'
                InputLabelProps={{ shrink: true }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </MenuItem>
            {tags.length > 0 && (
              <Flex
                sx={{
                  px: 2,
                  py: 1,
                  maxWidth: pickerInputRef?.current?.offsetWidth,
                  gap: 1,
                }}
                flexWrap='wrap'
              >
                {tags.map((tag) => (
                  <ProcessTagChip
                    tag={tag}
                    key={tag.id}
                    onClick={() => {
                      if (tagsFilter.includes(tag.id)) {
                        setTagsFilter(tagsFilter.filter((t) => t !== tag.id))
                      } else {
                        setTagsFilter([...tagsFilter, tag.id])
                      }
                    }}
                    active={!!tagsFilter.includes(tag.id)}
                    onDelete={
                      tagsFilter.includes(tag.id)
                        ? () => {
                            setTagsFilter(
                              tagsFilter.filter((t) => t !== tag.id),
                            )
                          }
                        : undefined
                    }
                  />
                ))}
              </Flex>
            )}
          </>
          <Collapse in={!!recent?.length}>
            <Text fontSize={12} color='text.secondary' sx={{ mb: 1.5, ml: 2 }}>
              {localizedStrings.recent}
            </Text>
            <Divider />
          </Collapse>
          {recent?.map((template, index) => {
            return (
              <MenuItem
                key={`recent-${template.id}`}
                value={template.id}
                onClick={(e) => {
                  e.stopPropagation()
                  handleSelect(template.id)
                }}
              >
                {template.name}
              </MenuItem>
            )
          })}
          <Collapse in={!!recent?.length}>
            <Text fontSize={12} color='text.secondary' sx={{ my: 1.5, ml: 2 }}>
              {localizedStrings.templates}
            </Text>
            <Divider />
          </Collapse>
          {templates?.length ? (
            templates?.map((template, index) => {
              return (
                <MenuItem
                  key={template.id}
                  value={template.id}
                  data-test={`select-template-dropdown-option-${index}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSelect(template.id)
                  }}
                >
                  {template.name}
                </MenuItem>
              )
            })
          ) : (
            <Text sx={{ px: 2, py: 0.75 }}>
              {localizedStrings.noResultsFoundPleaseChangeSearch}
            </Text>
          )}
        </Box>
      </ClickAwayListener>
    </TextField>
  )
}
