import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  RequestTypes,
  taskTemplateKeys,
} from '@core/react-query/features/tasks/templates'
import {
  AvailableTaskTemplatesWrapper,
  IAvailableTaskTemplatesWrapper,
} from '@core/entities/task/TaskTemplate'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const taskTemplateTransform = (data: IAvailableTaskTemplatesWrapper) =>
  AvailableTaskTemplatesWrapper.new(data)

export const useAvailableTaskTemplates = (
  payload: RequestTypes['getAvailableTemplates'],
  options?: UseQueryOptions<
    IAvailableTaskTemplatesWrapper,
    RequestErrorType,
    AvailableTaskTemplatesWrapper
  >,
) => {
  return useQuery<
    IAvailableTaskTemplatesWrapper,
    RequestErrorType,
    AvailableTaskTemplatesWrapper
  >({
    select: taskTemplateTransform,
    ...taskTemplateKeys.getAvailableTemplates(payload),
    ...options,
  })
}
