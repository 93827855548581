import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { TaskTemplateVersion } from '@core/entities/task/TaskTemplateVersion'
import {
  taskTemplateKeys,
  RequestTypes,
} from '@core/react-query/features/tasks/templates'

export const useCreateTaskTemplateVersion = (
  options?: UseMutationOptions<
    TaskTemplateVersion,
    string,
    RequestTypes['createTaskTemplateVersion']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...taskTemplateKeys.createTaskTemplateVersion,
    ...options,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: taskTemplateKeys.getTaskTemplateVersions({
          templateId: variables.templateId,
        }).queryKey,
      })
    },
  })
}
