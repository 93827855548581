import { Box, Text } from '@fundamentals'
import React from 'react'
import {
  CountdownQuestionRules,
  QuestionTypeComponentType,
} from '@core/components/templateAnswersForm'
import { MultipleChoice } from '@common/MultipleChoice'
import produce from 'immer'
import { localizedStrings } from '@core/strings'

const CountdownQuestion: QuestionTypeComponentType = ({
  question,
  inputProps: { value, onChange, onBlur } = {},
  readOnly,
}) => {
  if (onChange && !readOnly) {
    return (
      <MultipleChoice
        options={question.durationMinutesOptions.map((duration, index) => {
          return {
            label: `${duration} ${
              duration === 1
                ? localizedStrings.minute
                : localizedStrings.minutes
            }`,
            value: duration,
          }
        })}
        selected={[question.countdownDuration]}
        onSelect={(selected) => {
          const newValue = produce(value, (draft) => {
            draft.countdownDuration = selected[0]
          })
          onChange(newValue)
          onBlur()
        }}
        maxSelect={1}
        showCheckbox
        multiSelect={false}
        dataTest={`${question.id}-CONFIGURE_POST_PERMIT_CHECKS_COUNTDOWN`}
      />
    )
  }

  return (
    <Box data-test={`${question.id}-CONFIGURE_POST_PERMIT_CHECKS_COUNTDOWN`}>
      <Text>
        {`${question?.countdownDuration} ${
          question?.countdownDuration === 1
            ? localizedStrings.minute
            : localizedStrings.minutes
        }`}
      </Text>
    </Box>
  )
}

CountdownQuestion.rules = CountdownQuestionRules

export default CountdownQuestion
