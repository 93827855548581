import { useMutation, useQueryClient, QueryClient } from '@tanstack/react-query'
import { RequestTypes } from '@core/react-query/features/tasks/task'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { taskKeys } from '../taskKeys'
import { ITask } from '@core/entities/task/Task'
import { useMutateQueryData } from '@core/react-query/hooks'
import { tasksKeys } from '@core/react-query/features/tasks/tasks'
import { OfflineEntityType } from '@core/react-query/defaultMutations'

export const getCreateTaskMutationOptions = (queryClient: QueryClient) => {
  const { updateInfiniteQueryItem, deleteInfiniteQueryItem } =
    useMutateQueryData(queryClient)
  return {
    ...taskKeys.createTask,
    meta: {
      name: 'Create Task',
      offlineEnabled: true,
      variablesType: OfflineEntityType.TASK_SUBMISSION,
    },
    onSuccess: (task, variables) => {
      queryClient.setQueryData(
        taskKeys.byId({ taskId: task.id }).queryKey,
        task,
      )
      if (variables.draftId) {
        deleteInfiniteQueryItem(
          tasksKeys.draftTasks({
            organisationId: task.organisationId,
            projectIds: [variables.projectId],
          }).queryKey,
          variables.draftId,
        )
      }
      updateInfiniteQueryItem(
        tasksKeys
          .allTasks({
            organisationId: task.organisationId,
            projectIds: [variables.projectId],
          })
          .queryKey.slice(0, -3),
        task,
      )
    },
  }
}

export const useCreateTask = () => {
  const queryClient = useQueryClient()

  return useMutation<ITask, RequestErrorType, RequestTypes['createTask']>(
    getCreateTaskMutationOptions(queryClient),
  )
}
