import { TaskTemplateProperties } from '@core/entities/task/TaskTemplateProperties'
import {
  taskTemplateKeys,
  RequestTypes,
} from '@core/react-query/features/tasks/templates'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

export const useUpdateTaskTemplateProperties = (
  options?: UseMutationOptions<
    TaskTemplateProperties,
    string,
    RequestTypes['updateTaskTemplateProperties']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...taskTemplateKeys.updateTaskTemplateProperties,
    ...options,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: taskTemplateKeys.getTaskTemplate({
          templateId: variables.templateId,
        }).queryKey,
      })
      queryClient.invalidateQueries({
        queryKey: taskTemplateKeys.getTaskTemplateVersion({
          templateId: variables.templateId,
          versionId: variables.versionId,
        }).queryKey,
      })
    },
  })
}
