import React, { useRef } from 'react'
import { Box, Text, Flex } from '@fundamentals'
import { useHtml } from '@common/hooks'
import { useDeepCompareMemo } from 'use-deep-compare'
import {
  AttachmentTypes,
  QuestionTypeComponentType,
} from '@core/components/templateAnswersForm'
import { AlphaNumericQuestion } from '@elements/Questions/questionTypes/AlphaNumericQuestion'
import MultipleChoiceQuestion from '@elements/Questions/questionTypes/MultipleChoiceQuestion'
import DateQuestion from '@elements/Questions/questionTypes/DateQuestion'
import NumericQuestion from '@elements/Questions/questionTypes/NumericQuestion'
import PreConditionQuestion from '@elements/Questions/questionTypes/PreConditionQuestion'
import AcknowledgeQuestion from '@elements/Questions/questionTypes/AcknowledgeQuestion'
import { DocumentQuestion } from '@elements/Questions/questionTypes/DocumentQuestion'
import LinkPermitsQuestion from '@elements/Questions/questionTypes/LinkPermitsQuestion'
import LinkChecklistsQuestion from '@elements/Questions/questionTypes/LinkChecklistsQuestion'
import { Controller, useFormContext } from 'react-hook-form'
import {
  ITemplateQuestion,
  SubmissionDocument,
  SubmissionProof,
} from '@core/entities/template/TemplateQuestion'
import { localizedStrings } from '@core/strings'
import { TextInput } from '@common/material'
import { AttachmentInput } from '@elements/Questions/AttachmentInput'
import produce from 'immer'
import { AlertBox } from '@common/AlertBox'
import CameraAltOutlined from '@mui/icons-material/CameraAltOutlined'
import CaptureSignature from '@common/CaptureSignature'
import { FormHelperText, useTheme } from '@mui/material'
import useConstants from '@core/providers/useConstants'
import moment from 'moment'
import { useTimeStamp } from '@core/hooks/useTimeStamp'
import CountdownQuestion from '@elements/Questions/questionTypes/CountdownQuestion'

const WrapperErrors = {
  SIGNATURE_REQUIRED: localizedStrings.requiresSignature,
  PROOF_REQUIRED: localizedStrings.requiresProof,
  ADDITIONAL_INFORMATION_REQUIRED:
    localizedStrings.requiresAdditionalInformation,
}

type QuestionWrapperProps = {
  question: ITemplateQuestion & { [key: string]: any }
  questionIndex: number
  groupIndex: number
  questionNumber: number
  actionButton?: React.ReactNode
  readOnly?: boolean
  attachmentTypes?: AttachmentTypes[]
  attachments?: Array<
    | (SubmissionProof & { type: string })
    | (SubmissionDocument & { type: string })
  >
}

const QuestionWrapperBase: React.FC<QuestionWrapperProps> = ({
  children,
  actionButton,
  question,
  questionIndex,
  groupIndex,
  questionNumber,
  readOnly,
  attachmentTypes,
  attachments,
}) => {
  const { constants } = useConstants()
  const theme = useTheme()
  const { html } = useHtml(question.body)
  const formContext = useFormContext()
  const projectId = formContext?.getValues('projectId')
  const signatureRef = useRef(null)
  const { addTimeStampToTemplateQuestion: addTimeStamp } = useTimeStamp()

  const attachmentRequired =
    (question.questionType === 'DOCUMENT' && question.required) ||
    question.requiresProof === 'REQUIRED'

  const QuestionComponent =
    useDeepCompareMemo<QuestionTypeComponentType>(() => {
      switch (question.questionType) {
        case 'ALPHANUMERIC':
          return AlphaNumericQuestion
        case 'MULTIPLE_CHOICE':
          return MultipleChoiceQuestion
        case 'DATE':
          return DateQuestion
        case 'NUMERIC':
          return NumericQuestion
        case 'PRE_CONDITION':
          return PreConditionQuestion
        case 'ACKNOWLEDGE_INFORMATION':
          return AcknowledgeQuestion
        case 'DOCUMENT':
          return DocumentQuestion
        case 'LINK_PERMITS':
          return LinkPermitsQuestion
        case 'LINK_CHECKLISTS':
          return LinkChecklistsQuestion
        case 'CONFIGURE_POST_PERMIT_CHECKS_COUNTDOWN':
          return CountdownQuestion
        default:
          return null
      }
    }, [question])

  const addGlobalQuestionValidationRules = (rules) => {
    return {
      ...rules,
      validate: (value) => {
        const questionSpecificValidation = rules?.validate
          ? rules?.validate(value)
          : true
        if (questionSpecificValidation !== true)
          return questionSpecificValidation
        if (
          question?.requiresSignature === 'REQUIRED' &&
          !(value.localSignature || value.signatureUrl)
        ) {
          return WrapperErrors.SIGNATURE_REQUIRED
        }
        if (
          question?.requiresProof === 'REQUIRED' &&
          !value.submissionProofs?.length &&
          !value.submissionDocuments?.length
        ) {
          return WrapperErrors.PROOF_REQUIRED
        }
        if (
          question?.requiresAdditionalInformation === 'REQUIRED' &&
          !value.questionAdditionalInformation
        ) {
          return WrapperErrors.ADDITIONAL_INFORMATION_REQUIRED
        }

        return true
      },
    }
  }

  return (
    <Box data-test={`question-${questionIndex}`} data-id={question.id}>
      <Flex justifyContent='space-between' alignItems='center' mb={1}>
        <Text color='info.light' fontSize='12px' sx={{ mr: 2 }}>
          {`${groupIndex + 1} - ${questionNumber}`}
        </Text>
        {actionButton}
      </Flex>
      <Box
        dangerouslySetInnerHTML={{ __html: html }}
        color='grey.600'
        data-test='body'
        sx={{
          '& p': { mb: 1, fontSize: '14px' },
          '& ol': { paddingInlineStart: '40px' },
        }}
      />
      {QuestionComponent &&
        (readOnly ? (
          <>
            <QuestionComponent question={question} readOnly={readOnly} />
            {children}
          </>
        ) : (
          <Controller
            name={`questions.${groupIndex}.data.${questionIndex}`}
            rules={addGlobalQuestionValidationRules(QuestionComponent?.rules)}
            render={({
              field: { onChange, ...field },
              fieldState: { error },
            }) => {
              const isProofError =
                error?.message === WrapperErrors.PROOF_REQUIRED

              const signatureValue = field.value.localSignature
                ? URL.createObjectURL(field.value.localSignature)
                : field.value.signatureUrl

              const clearSignatureRef = () => {
                signatureValue && signatureRef.current?.clearSignature()
              }

              const handleChange = (
                question: ITemplateQuestion,
                clearSignature?: boolean,
              ) => {
                clearSignature && clearSignatureRef()
                addTimeStamp(onChange, clearSignature)(question)
              }

              return (
                <Box
                  sx={{
                    mt: error ? 2 : 0,
                    outlineWidth: error ? '2px' : '0',
                    // @ts-ignore
                    outlineColor: theme.palette.error.main,
                    outlineStyle: 'solid',
                    outlineOffset: '5px',
                    borderRadius: 0.1,
                    transition: 'all 0.3s',
                    padding: error ? 1.5 : 0,
                    pb: 0,
                  }}
                >
                  <QuestionComponent
                    question={question}
                    readOnly={readOnly}
                    error={error && !isProofError && error.message}
                    inputProps={{
                      onChange: (question) => {
                        handleChange(question, true)
                      },
                      ...field,
                    }}
                  />
                  {question?.requiresAdditionalInformation !==
                    'NOT_REQUIRED' && (
                    <TextInput
                      value={question?.questionAdditionalInformation}
                      onChange={(event) => {
                        const newValue = produce(
                          field.value,
                          (draft: ITemplateQuestion) => {
                            draft.questionAdditionalInformation =
                              event.target.value
                          },
                        ) as unknown as ITemplateQuestion

                        handleChange(newValue, true)
                      }}
                      minRows={3}
                      placeholder={localizedStrings.questionAdditionalInformation(
                        question.requiresAdditionalInformation === 'OPTIONAL',
                      )}
                      multiline
                      fullWidth
                      data-test={`${
                        question.id
                      }-${'question-additional-information'}`}
                      inputProps={{
                        'data-test': 'question-additional-information-input',
                      }}
                      sx={{ mt: 2 }}
                    />
                  )}

                  {(attachmentTypes || !!attachments.length) && (
                    <AttachmentInput
                      heading={localizedStrings.proofs}
                      enableLinkSiteBoxDocuments={true}
                      projectId={projectId}
                      inputProps={{
                        value: [
                          ...(field.value.submissionProofs?.map((proof) => ({
                            type: 'image',
                            ...proof,
                          })) || []),
                          ...(field.value.submissionDocuments?.map(
                            (document) => ({
                              type: 'application/pdf',
                              ...document,
                            }),
                          ) || []),
                        ],
                        onChange: (proofs) => {
                          const submissionProofs = proofs.filter((proof) =>
                            proof.type.includes('image'),
                          )
                          const submissionDocuments = proofs.filter((proof) =>
                            proof.type.includes('pdf'),
                          )

                          const newValue = produce(
                            field.value,
                            (draft: ITemplateQuestion) => {
                              draft.submissionProofs = submissionProofs
                              draft.submissionDocuments = submissionDocuments
                            },
                          ) as unknown as ITemplateQuestion

                          handleChange(newValue, true)
                          field.onBlur()
                        },
                      }}
                      attachmentTypes={attachmentTypes}
                      required={attachmentRequired}
                      maxFileSize={
                        constants.max_question_attachment_size_in_bytes
                      }
                      readOnly={!attachmentTypes}
                      error={isProofError && error.message}
                      dataTest={`${question.id}-attachment-input`}
                    />
                  )}
                  {question.requireProofFromCamera && !attachments?.length && (
                    <AlertBox
                      type='info'
                      icon={<CameraAltOutlined sx={{ color: 'info.main' }} />}
                      mt={2}
                    >
                      {localizedStrings.requireProofFromCameraNote}
                    </AlertBox>
                  )}
                  {question.requiresSignature !== 'NOT_REQUIRED' && (
                    <>
                      <CaptureSignature
                        ref={signatureRef}
                        inputProps={{
                          value: signatureValue,
                          onChange: (signatureFile) => {
                            const newValue = produce(
                              field.value,
                              (draft: ITemplateQuestion) => {
                                draft.localSignature = signatureFile
                              },
                            ) as unknown as ITemplateQuestion

                            handleChange(newValue)
                          },
                        }}
                        optional={question.requiresSignature === 'OPTIONAL'}
                        dataTest={question.id}
                      />
                      {field.value.lastEdit && signatureValue && (
                        <Text variant='body2' color='grey.600' mt={-0.5}>
                          {moment(field.value.lastEdit?.editedOn).format(
                            'DD/MM/YYYY HH:mm',
                          )}
                        </Text>
                      )}
                    </>
                  )}
                  {!!error && (
                    <Box>
                      <FormHelperText
                        sx={{
                          mt: 0.5,
                          ml: 0,
                          height: '100%',
                        }}
                        error
                      >
                        {error.message}
                      </FormHelperText>
                    </Box>
                  )}
                  {children}
                </Box>
              )
            }}
          />
        ))}
    </Box>
  )
}

export { QuestionWrapperBase }
