import { plainToInstance, Type } from 'class-transformer'
import moment from 'moment'
import { Entity } from '@core/entities/entity'
import { ITask } from './ITask'
import {
  DueDateConfig,
  TaskTemplatePriorityType,
} from '@core/entities/task/TaskTemplateProperties'
import { User } from '@core/entities/user'
import { TaskStep } from '@core/entities/task/TaskStep'
import { TemplateForm } from '@core/entities/template/TemplateForm'
import { ChangeRequest } from '@core/entities/permit/ChangeRequest'
import { TaskTemplateVersion } from '@core/entities/task/TaskTemplateVersion'
import { Project } from '@core/entities/project/Project'
import { TaskStatus } from './types'

export class Task extends Entity<ITask> {
  id: number

  summary: string

  priority: TaskTemplatePriorityType

  dueDate: string

  status: TaskStatus

  @Type(() => TaskStep)
  currentStep: TaskStep

  @Type(() => TaskStep)
  nextStep: TaskStep

  submittedSteps: { steps: Array<TaskStep> }

  @Type(() => User)
  assignee: User

  @Type(() => User)
  createdBy: User

  createdOn: string

  modifiedOn: string

  @Type(() => ChangeRequest)
  lastRequestedChange?: ChangeRequest

  // Returned in by-id responses
  @Type(() => TaskTemplateVersion)
  templateVersion?: TaskTemplateVersion

  // Returned in paginated responses
  template?: {
    id: number
    name: string
    templateVersionId: number
    version: number
  }

  @Type(() => Project)
  project: Project

  public static new(payload: unknown): Task {
    const entity = plainToInstance(Task, payload)

    return entity
  }

  public static calculateDueDate(
    rawDate: moment.Moment,
    priority: TaskTemplatePriorityType | undefined,
    dueDateConfig: DueDateConfig | undefined,
  ): string {
    // 1. First check if we need to calculate based on priority at all
    if (!dueDateConfig?.prioritySetsTheDueDate) {
      return rawDate.toISOString()
    }

    const { prioritySetsTheDueDateConfig } = dueDateConfig
    const { defaultPriorityValues, excludeWeekends } =
      prioritySetsTheDueDateConfig

    // 2. Look up the duration configuration for the selected priority
    const priorityConfig = defaultPriorityValues.find(
      (config) => config.priority === priority,
    )

    // If no priority config found, return original date
    if (!priorityConfig) {
      return rawDate.toISOString()
    }

    // 3. Calculate the due date based on priority duration
    const dueDate = moment()
    const durationInSeconds = priorityConfig.maxDurationSeconds

    // 4a. Simple case: If weekends are included, just add the duration
    if (!excludeWeekends) {
      dueDate.add(durationInSeconds, 'seconds')
      return dueDate.toISOString()
    }

    // 4b. Complex case: Add duration while skipping weekends
    let secondsAdded = 0
    while (secondsAdded < durationInSeconds) {
      dueDate.add(1, 'second')
      const dayOfWeek = dueDate.day()
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        secondsAdded++
      }
    }

    return dueDate.toISOString()
  }

  public isNextStepApproval(): boolean {
    return this.nextStep?.type === 'APPROVAL'
  }

  public isNextStepWork(): boolean {
    return this.nextStep?.type === 'WORK'
  }

  // If there is no next step, then the task is done
  public isNextStepDone(): boolean {
    return !this.nextStep
  }

  public isInProgress(): boolean {
    return this.status === 'IN_PROGRESS'
  }

  public isAwaitingApproval(): boolean {
    return this.status === 'AWAITING_APPROVAL'
  }

  public isCancelled(): boolean {
    return this.status === 'CANCELLED'
  }

  public isDone(): boolean {
    return this.status === 'DONE'
  }

  public getCurrentStepQuestions(): TemplateForm {
    return this.currentStep?.stepQuestions
  }

  public hasCurrentStepQuestions(): boolean {
    return this.currentStep?.stepQuestions?.questions?.length > 0
  }

  public getNextStepQuestions(): TemplateForm {
    return this.nextStep?.stepQuestions
  }

  public hasNextStepQuestions(): boolean {
    return this.nextStep?.stepQuestions?.questions?.length > 0
  }

  public hasRequestedChanges(): boolean {
    return !!this.lastRequestedChange && !this.lastRequestedChange?.addressedBy
  }

  public hasRequestedChangesComment(): boolean {
    return !!this.lastRequestedChange?.comment
  }

  public hasAddressedChanges(): boolean {
    return !!this.lastRequestedChange && !!this.lastRequestedChange?.addressedBy
  }

  public isOverdue(): boolean {
    return (
      moment().isAfter(this.dueDate) &&
      !['DONE', 'CANCELLED'].includes(this.status)
    )
  }
}
