import { Entity } from '@core/entities/entity'
import { plainToInstance } from 'class-transformer'
import { ITaskTemplateRole } from './ITaskTemplateRole'

export class TaskTemplateRole extends Entity<ITaskTemplateRole> {
  id: number
  name: string
  description: string

  public static new(payload: unknown): TaskTemplateRole {
    const entity = plainToInstance(TaskTemplateRole, payload)

    return entity
  }
}
