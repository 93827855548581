import { localizedStrings } from '@core/strings'
import React, { useEffect } from 'react'
import { useModal } from 'webapp/components/common/GlobalModal'
import {
  DialogActions,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  MenuItem,
  TextField,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Controller, useForm } from 'react-hook-form'
import {
  RegisterItem,
  RegisterItemInspectionProperty,
} from '@core/entities/register/RegisterItem'
import { ModalDialogContainer } from '@common/modal/ModalDialogContainer'
import { PageUrls } from '@core/page-urls'
import { useRouter } from 'next/router'
import { encodeReturnUrl } from 'webapp/utils'

interface NewInspectionModalProps {
  organisationId: number
  projectId: number
  registerId: number
  registerItem: RegisterItem
  inspections: RegisterItemInspectionProperty<string>[]
  inspectionId?: string
}

type FormSchema = {
  inspectionId: string
  inspectionTemplateId: number
}

export const NewInspectionModal: React.FC<NewInspectionModalProps> = ({
  organisationId,
  projectId,
  registerId,
  registerItem,
  inspectionId,
  inspections,
}) => {
  const { hideModal } = useModal()
  const router = useRouter()

  const { control, watch, handleSubmit, setValue } = useForm<FormSchema>({
    defaultValues: {
      inspectionId,
      inspectionTemplateId: undefined,
    },
  })

  const formInspectionId = watch('inspectionId')
  const formTemplateId = watch('inspectionTemplateId')

  const inspectionTemplates =
    inspections.find(
      ({ inspection }) => inspection.inspectionId === formInspectionId,
    )?.inspection?.checklistTemplates ?? []

  useEffect(() => {
    if (inspectionTemplates.length === 1) {
      setValue('inspectionTemplateId', inspectionTemplates[0].id, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    } else {
      setValue('inspectionTemplateId', undefined)
    }
  }, [inspectionTemplates, setValue])

  const onSubmit = (data: FormSchema) => {
    const returnUrl = encodeReturnUrl({
      pathname: router.pathname,
      query: router.query,
    })

    router.push({
      pathname: PageUrls.newChecklistProject({
        organisationId: organisationId,
        projectId: projectId,
      }),
      query: {
        registerId,
        registerItemId: registerItem.id,
        projectId: projectId,
        inspectionId: data.inspectionId,
        inspectionTemplateId: data.inspectionTemplateId,
        returnUrl,
      },
    })

    hideModal()
  }

  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20, mb: 0 }}
      >
        {localizedStrings.inspection}
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <Controller
          name='inspectionId'
          control={control}
          rules={{ required: localizedStrings.pleaseSelectAnInspection }}
          render={({ field, fieldState }) => (
            <TextField
              placeholder={localizedStrings.selectInspection}
              label={localizedStrings.selectInspection}
              error={!!fieldState.error}
              sx={{ mt: 2 }}
              fullWidth
              select
              {...field}
            >
              {inspections.map(({ inspection, name }) => (
                <MenuItem
                  key={inspection.inspectionId}
                  value={inspection.inspectionId}
                >
                  {name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          name='inspectionTemplateId'
          control={control}
          rules={{ required: localizedStrings.pleaseSelectATemplate }}
          render={({
            field: { value, onChange, ...fieldProps },
            fieldState,
          }) => (
            <TextField
              placeholder={localizedStrings.selectTemplate}
              label={localizedStrings.selectTemplate}
              error={!!fieldState.error}
              sx={{ mt: 2 }}
              fullWidth
              select
              value={value ?? ''}
              onChange={onChange}
              {...fieldProps}
            >
              {!inspectionTemplates.length && (
                <MenuItem disabled value=''>
                  {localizedStrings.noOptions}
                </MenuItem>
              )}
              {inspectionTemplates.map((inspectionTemplate) => (
                <MenuItem
                  key={inspectionTemplate.id}
                  value={inspectionTemplate.id}
                >
                  {inspectionTemplate.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </DialogContent>

      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button variant='outlined' onClick={hideModal}>
          {localizedStrings.cancel}
        </Button>
        <Button
          variant='contained'
          data-test='submit-register-inspection'
          onClick={handleSubmit(onSubmit)}
        >
          {localizedStrings.submit}
        </Button>
      </DialogActions>
    </ModalDialogContainer>
  )
}
