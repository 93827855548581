import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { TaskTemplateRole } from '@core/entities/task/TaskTemplateRole'
import { TemplateForm } from '@core/entities/template/TemplateForm'
import { ITaskTemplateStep } from './ITaskTemplateStep'
import { TaskTemplateStepType } from './types'

export class TaskTemplateStep extends Entity<ITaskTemplateStep> {
  id: string
  type: TaskTemplateStepType
  name: string
  description: string

  // Used for displaying the selected role
  @Type(() => TaskTemplateRole)
  role: TaskTemplateRole
  // Used when updating task template steps (form)
  roleId?: number

  // Used when updating task template steps (form)
  @Type(() => TemplateForm)
  stepQuestions: TemplateForm

  public static new(payload: unknown): TaskTemplateStep {
    const entity = plainToInstance(TaskTemplateStep, payload)

    return entity
  }

  public toServer(): Omit<ITaskTemplateStep, 'role'> {
    const roleId = this?.role?.id ?? this?.roleId

    const stepForServer: Omit<ITaskTemplateStep, 'role'> = {
      id: this?.id,
      type: this?.type,
      name: this?.name,
      description: this?.description,
      roleId: roleId,
      stepQuestions: this?.stepQuestions?.toPlain(),
    }

    return stepForServer
  }
}
