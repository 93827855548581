import { Entity } from '@core/entities/entity'
import { ITaskAssignableUser } from './ITaskAssignableUser'
import { plainToInstance } from 'class-transformer'
import { ProjectRole } from '@core/entities/user'

export class TaskAssignableUser extends Entity<ITaskAssignableUser> {
  id: number

  firstName: string

  lastName: string

  name: string

  email: string

  company: string

  roles: Array<ProjectRole>

  status: string

  lastActive: string

  authGroups: Array<{
    id: number
    name: string
    userCount: number
    permittedActions: string
  }>

  public static new(payload: unknown): TaskAssignableUser {
    const entity = plainToInstance(TaskAssignableUser, payload)

    return entity
  }
}
