import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { RequestTypes } from './requestTypes'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { PageResponse } from '@core/entities/pagination'
import { ITask } from '@core/entities/task/Task'

const tasksQueryKeys = createQueryKeys('tasks', {
  allTasks: ({
    organisationId,
    projectIds,
    page,
    size,
  }: RequestTypes['getAllTasks']) => {
    return {
      queryKey: [organisationId, projectIds, page, size],
      queryFn: async () => {
        const queryParams = createQueryKeyString({
          organisationId,
          projectIds,
          page,
          size,
        })
        const { data } = await APIAxiosInstance.get(`tasks${queryParams}`)

        return data as PageResponse<ITask>
      },
    }
  },
  myTasks: ({
    organisationId,
    projectIds,
    page,
    size,
  }: RequestTypes['getMyTasks']) => {
    return {
      queryKey: [organisationId, projectIds, page, size],
      queryFn: async () => {
        const queryParams = createQueryKeyString({
          organisationId,
          projectIds,
          page,
          size,
        })
        const { data } = await APIAxiosInstance.get(`tasks${queryParams}`)

        return data as PageResponse<ITask>
      },
    }
  },
  draftTasks: ({
    organisationId,
    projectIds,
    page,
    size,
  }: RequestTypes['getDrafts']) => {
    return {
      queryKey: [organisationId, projectIds, page, size],
      queryFn: async () => {
        const queryParams = createQueryKeyString({
          organisationId,
          projectIds,
          page,
          size,
        })
        // TODO: Uncomment this when backend is implemented for drafts
        // const { data } = await APIAxiosInstance.get(
        //   `tasks/drafts${queryParams}`,
        // )

        // return data as PageResponse<ITask>
        return null
      },
    }
  },
})

const tasksMutationKeys = createMutationKeys('tasks', {})

const tasksKeys = mergeQueryKeys(tasksQueryKeys, tasksMutationKeys).tasks

export { tasksKeys }
