import React from 'react'
import { LinearProgress } from '@common/material'
import { TransitionGroup } from 'react-transition-group'
import { Collapse } from '@mui/material'
import {
  AuditResult,
  AuditResultScopeTypes,
} from '@core/entities/audit/AuditResult'
import { RegisterItem } from '@core/entities/register/RegisterItem'

import { TimelineItem } from './TimelineItem'
import { Virtuoso } from 'react-virtuoso'
import { Box, Flex, Text } from '@fundamentals'
import { useScrolling } from '@common/hooks'
import { localizedStrings } from '@core/strings'

type TimelineProps = {
  isLoading?: boolean
  auditResults: Array<AuditResult>
  registerItem?: RegisterItem
  scope?: AuditResultScopeTypes
  virtualize?: boolean
  onEndReached?: () => void
  hasNextPage?: boolean
}

export const Timeline: React.FC<TimelineProps> = ({
  isLoading,
  auditResults = [],
  registerItem,
  scope,
  virtualize,
  onEndReached,
  hasNextPage,
}) => {
  const { scrollingElementId } = useScrolling()

  return (
    <>
      <TransitionGroup>
        {isLoading && (
          <Collapse>
            <LinearProgress />
          </Collapse>
        )}
        {!virtualize &&
          !isLoading &&
          auditResults.map((auditResult) => {
            return (
              <Collapse key={auditResult.id}>
                <TimelineItem
                  scope={scope}
                  auditResult={auditResult}
                  registerItem={registerItem}
                  auditResults={auditResults}
                />
              </Collapse>
            )
          })}
      </TransitionGroup>
      {virtualize && (
        <Virtuoso
          data={auditResults}
          customScrollParent={document.getElementById(scrollingElementId)}
          context={{ hasNextPage }}
          endReached={onEndReached}
          itemContent={(_, auditResult) => {
            return (
              <TimelineItem
                scope={scope}
                auditResult={auditResult}
                registerItem={registerItem}
                auditResults={auditResults}
              />
            )
          }}
          components={{ Footer }}
        />
      )}
    </>
  )
}

const Footer = ({ context: { hasNextPage } }) => {
  return (
    <Box pb={2} pt={1}>
      {hasNextPage && <LinearProgress />}
      <Flex justifyContent='center'>
        {!hasNextPage && <Text>{localizedStrings.noMoreResults}</Text>}
      </Flex>
    </Box>
  )
}
