import { localizedStrings } from '@core/strings'
import { ITemplateEditorChecklistTemplateVersion } from '@core/entities/checklist/TemplateEditorChecklistTemplateVersion/ITemplateEditorChecklistTemplateVersion'
import { Entity } from '@core/entities/entity'
import { Type } from 'class-transformer'
import { plainToInstance } from 'class-transformer'
import {
  TemplateEditorChecklistTemplateVersionPermissions,
  TemplateEditorChecklistTemplateVersionStatus,
} from '@core/entities/checklist/TemplateEditorChecklistTemplateVersion/types'
import { TemplateFormWrapper } from '@core/entities/template/TemplateFormWrapper'
import { TemplateForm } from '@core/entities/template/TemplateForm'
import { User } from '@core/entities/user'
import { TaskTemplate } from '@core/entities/task/TaskTemplate'

export class TemplateEditorChecklistTemplateVersion extends Entity<ITemplateEditorChecklistTemplateVersion> {
  id: number

  organisationId: number

  projectId?: number

  organisationName: string

  name: string

  description: string

  templateId: number

  version: number

  createdOn: string

  modifiedOn: string

  @Type(() => User)
  createdBy: User

  @Type(() => User)
  modifiedBy: User

  status: TemplateEditorChecklistTemplateVersionStatus

  @Type(() => TemplateFormWrapper)
  templateQuestions: Array<TemplateFormWrapper>

  clonable: boolean

  geoLocationInputType: 'WHAT_3_WORDS' | 'DRAW_ON_MAP'
  requireGeolocationForChecklist?: boolean

  ownedByRegisterTemplate?: any

  usedForInspections?: boolean

  defaultInspectionsTaskTemplate?: TaskTemplate

  permittedActions: TemplateEditorChecklistTemplateVersionPermissions

  public isActive(): boolean {
    return this.status === 'ACTIVE'
  }

  public isReadOnly(): boolean {
    return this.status !== 'DRAFT'
  }

  public static new(payload: unknown): TemplateEditorChecklistTemplateVersion {
    const entity = plainToInstance(
      TemplateEditorChecklistTemplateVersion,
      payload,
    )

    return entity
  }

  public getMainQuestions(): TemplateForm {
    return this.templateQuestions.find((templateQuestions) => {
      return templateQuestions.questionsType === 'MAIN_QUESTIONS'
    })?.checklistQuestions
  }

  public static statusColor(
    status: TemplateEditorChecklistTemplateVersionStatus,
  ) {
    switch (status) {
      case 'ACTIVE':
        return 'success'
      case 'ARCHIVED':
        return 'default'
      case 'DISCARDED':
        return 'warning'
      case 'DRAFT':
        return 'default'
      default:
        return 'default'
    }
  }

  public static statusText(
    status: TemplateEditorChecklistTemplateVersionStatus,
  ) {
    switch (status) {
      case 'ACTIVE':
        return localizedStrings.active
      case 'ARCHIVED':
        return localizedStrings.archived
      case 'DISCARDED':
        return localizedStrings.discarded
      case 'DRAFT':
        return localizedStrings.draft
      default:
        return status
    }
  }
}
