import { localizedStrings } from '@core/strings'
import { Entity } from '@core/entities/entity'
import { IPermitTask } from './IPermitTask'
import { plainToInstance } from 'class-transformer'
import {
  PermitTaskPermittedActions,
  PermitTaskStatus,
  PermitTaskType,
} from './types'
import { User } from '@core/entities/user'

export class PermitTask extends Entity<IPermitTask> {
  id: number

  name: string

  type: PermitTaskType

  status: PermitTaskStatus

  ordinal: number

  permitId: number

  assignedTo: User

  organisationId: number

  projectId: number

  checklistTemplateId?: number

  checklistId?: number
  createdOn: string

  modifiedOn: string

  permittedActions: Array<PermitTaskPermittedActions>

  permitTemplateVersionRoleId?: number
  permitTemplateVersionRoleName?: string
  permitTemplateVersionRoleGroupIds?: Array<number>

  public static new(payload: unknown): PermitTask {
    const entity = plainToInstance(PermitTask, payload)

    return entity
  }

  public static getNumberOfTasksByStatus(
    tasks: IPermitTask[],
    status: PermitTaskStatus,
  ): number {
    return tasks?.filter((task) => task.status === status).length
  }

  public checkTaskType(type: PermitTaskType): boolean {
    return this.type === type
  }

  public checkTaskStatus(status: PermitTaskStatus): boolean {
    return this.status === status
  }

  public checkForMultipleTaskTypes(types: PermitTaskType[]): boolean {
    return types.includes(this.type)
  }

  public checkForMultipleTaskStatuses(statuses: PermitTaskStatus[]): boolean {
    return statuses.includes(this.status)
  }

  public getStatusText(status: PermitTaskStatus): string {
    switch (status) {
      case 'TO_DO':
        return localizedStrings.toDo
      case 'DONE':
        return localizedStrings.completed
      case 'CANCELLED':
        return localizedStrings.cancelled
      default:
        return ''
    }
  }

  public getStatusColor() {
    switch (this.status) {
      case 'TO_DO':
        return 'warning'
      case 'DONE':
        return 'primary'
      case 'CANCELLED':
        return 'error'
      default:
        return 'warning'
    }
  }

  public hasPermissionsToCompleteAction() {
    return this.permittedActions.includes('DO_TASK')
  }
}
