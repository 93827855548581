import { localizedStrings } from '@core/strings'
import React from 'react'
import { PermitTimelineTabProps } from '../types'
import { Timeline } from '@common/Timeline'
import { Button } from '@common/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { useModal } from '@common/GlobalModal'
import { usePermitTimeline } from '@core/react-query/features/permits/permit/hooks/usePermitTimeline'
import { useTimelineMockData } from '@common/Timeline/mockData/useTimelineMockData'
import { Filters } from '@elements/Filters'
import { TimelineMapModal } from '@modules/geolocation'

export const PermitTimelineTab: React.FC<PermitTimelineTabProps> = ({
  permit,
}) => {
  const { showModal } = useModal()
  const {
    data: timeline,
    error,
    isLoading,
  } = usePermitTimeline({ permitId: permit?.id })
  const { filters, filterStore, mockAuditResults, mock } = useTimelineMockData()
  const auditResults = mock ? mockAuditResults : timeline

  const hasItemsWithCoordinates =
    auditResults?.filter((t) => !!t?.getCoordinates())?.length > 0

  return (
    <>
      {mock && <Filters filters={filters} store={filterStore} />}
      {hasItemsWithCoordinates && (
        <Button
          sx={{ mb: 2 }}
          startIcon={<LocationOnIcon />}
          onClick={() => {
            showModal(
              <TimelineMapModal
                projectId={permit.project.id}
                featurePoint={permit.getW3wLocation()}
                featurePolygon={permit.getGeolocationAreas()}
                timeline={auditResults}
              />,
            )
          }}
        >
          {localizedStrings.viewOnMap}
        </Button>
      )}
      <ErrorMessage>{error}</ErrorMessage>
      <Timeline
        scope='PERMIT'
        isLoading={isLoading}
        auditResults={auditResults}
      />
    </>
  )
}
