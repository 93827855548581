import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  RequestTypes,
  taskTemplateKeys,
} from '@core/react-query/features/tasks/templates'
import {
  ITaskTemplateRole,
  TaskTemplateRole,
} from '@core/entities/task/TaskTemplateRole'

const taskTemplateRolesTransform = (response: { roles: ITaskTemplateRole[] }) =>
  response?.roles?.map(TaskTemplateRole.new)

export const useGetTaskTemplateRoles = (
  payload: RequestTypes['getTaskTemplateRoles'],
  options?: UseQueryOptions<ITaskTemplateRole[], string, TaskTemplateRole[]>,
) => {
  return useQuery({
    select: taskTemplateRolesTransform,
    ...taskTemplateKeys.getTaskTemplateRoles(payload),
    ...options,
  })
}
