import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { ITaskTemplate } from './ITaskTemplate'
import { TaskTemplatePermittedActions, TaskTemplateStatus } from './types'
import { User } from '@core/entities/user'
import { TaskTemplateVersion } from '@core/entities/task/TaskTemplateVersion'
import {
  ITaskTemplateProperties,
  TaskTemplatePriorityType,
} from '@core/entities/task/TaskTemplateProperties'
import { ProcessTagsWrapper } from '@core/entities/processTags/ProcessTagsWrapper'
export class TaskTemplate extends Entity<ITaskTemplate> {
  id: number

  name: string

  description: string

  status: TaskTemplateStatus

  @Type(() => TaskTemplateVersion)
  currentVersion?: TaskTemplateVersion

  permittedActions: TaskTemplatePermittedActions[]

  @Type(() => User)
  modifiedBy: User

  modifiedOn: string

  // NOTE: This is not implemented by the API yet.
  code?: string

  // NOTE: This is not implemented by the API yet.
  @Type(() => ProcessTagsWrapper)
  processTags?: ProcessTagsWrapper

  public static new(payload: unknown): TaskTemplate {
    const entity = plainToInstance(TaskTemplate, payload)

    return entity
  }

  public getStatusChipColor() {
    switch (this.status) {
      case 'DRAFT':
        return 'default'
      case 'ARCHIVED':
        return 'default'
      default:
        return 'primary'
    }
  }

  public static statusString(status: TaskTemplateStatus): string {
    switch (status) {
      case 'DRAFT':
        return 'Draft'
      case 'ARCHIVED':
        return 'Archived'
      case 'LIVE':
        return 'Live'
      default:
        return status
    }
  }

  public getStatusString(): string {
    switch (this.status) {
      case 'DRAFT':
        return 'Draft'
      case 'ARCHIVED':
        return 'Archived'
      case 'LIVE':
        return 'Live'
      default:
        return status
    }
  }

  public getCreationStepId(): string {
    // The creation step is always the first step in the template
    return this.currentVersion?.steps?.taskSteps?.[0]?.id
  }

  public getDueDateConfig(): ITaskTemplateProperties['dueDateConfig'] {
    return this.currentVersion?.properties?.dueDateConfig
  }

  public getAttachmentRequired(): boolean {
    return !this.currentVersion?.properties?.attachmentsConfig?.optional
  }

  public getDueDateRequired(): boolean {
    return !this.currentVersion?.properties?.dueDateConfig?.optional
  }

  public getDueDatePrioritySetsTheDueDate(): boolean {
    return this.currentVersion?.properties?.dueDateConfig
      ?.prioritySetsTheDueDate
  }

  public getPriorityOptions(): TaskTemplatePriorityType[] {
    return this.currentVersion?.properties?.dueDateConfig?.prioritySetsTheDueDateConfig?.defaultPriorityValues.map(
      (value) => value.priority,
    )
  }

  public getPriorityDefaultValue(): TaskTemplatePriorityType {
    return this.currentVersion?.properties?.priorityConfig?.defaultValue
  }
}
