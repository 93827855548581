import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { taskKeys, RequestTypes } from '@core/react-query/features/tasks/task'
import { AuditResult, IAuditResult } from '@core/entities/audit/AuditResult'
import { PageResponse } from '@core/entities/pagination/PageResponse'
import { usePageableQuery } from '@core/react-query/hooks'

const auditResultTransform: (
  data: PageResponse<IAuditResult>,
) => PageResponse<AuditResult> = (data) => {
  return {
    ...data,
    content: data?.content?.map((audit) => AuditResult.new(audit)),
  }
}

export const useGetTaskTimeline = (
  { taskId }: RequestTypes['getTaskTimeline'],
  options?: UseInfiniteQueryOptions<
    PageResponse<IAuditResult>,
    string,
    PageResponse<AuditResult>
  >,
) => {
  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options?.enabled,
    queryKey: taskKeys.byId({ taskId })._ctx.timeline.queryKey,
    dataTransformer: auditResultTransform,
  })

  return useInfiniteQuery({
    ...infiniteQueryOptions,
    ...taskKeys.byId({ taskId })._ctx.timeline,
    ...options,
  })
}
