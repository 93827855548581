export const downloadCSV = (csvContent: string, filename: string): void => {
  // Convert the CSV string to a Blob
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

  // Create a temporary download link
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.href = url
  link.download = filename

  // Append the link to the body (required for Firefox)
  document.body.appendChild(link)

  // Trigger the download
  link.click()

  // Clean up
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}
