export enum TaskTabs {
  ALL_TASKS = 'All Tasks',
  MY_TASKS = 'My Tasks',
  DRAFTS = 'Drafts',
}

export type TaskStatus =
  | 'IN_PROGRESS'
  | 'AWAITING_APPROVAL'
  | 'DONE'
  | 'CANCELLED'
  | 'DRAFT'
