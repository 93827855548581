import { capitalize } from 'lodash'
import { FieldError } from 'react-hook-form'
import { Chip } from '@common/material'
import { Box, Text, Flex } from '@fundamentals'
import {
  TaskTemplatePriorityType,
  TaskTemplateProperties,
} from '@core/entities/task/TaskTemplateProperties'

interface TaskPriorityPickerProps {
  priorityOptions: TaskTemplatePriorityType[]
  value: TaskTemplatePriorityType
  onChange: (priority: TaskTemplatePriorityType) => void
  error?: FieldError
}

export const TaskPriorityPicker: React.FC<TaskPriorityPickerProps> = ({
  value,
  onChange,
  priorityOptions,
  error,
}) => {
  const getBorderColor = (priority: TaskTemplatePriorityType) => {
    if (error) return '#d32f2f'
    if (value === priority) return '#3B82F6'
    return '#E5E7EB'
  }

  const getBackgroundColor = (priority: TaskTemplatePriorityType) => {
    if (value === priority) return 'rgba(59, 130, 246, 0.1)'
    return 'transparent'
  }

  const getHoverBackgroundColor = (priority: TaskTemplatePriorityType) => {
    if (value === priority) return 'rgba(59, 130, 246, 0.15)'
    return 'rgba(0, 0, 0, 0.04)'
  }

  return (
    <Box>
      <Flex color='grey.600' alignItems='center'>
        <Text fontSize='14px' mb={1}>
          {'Priority'}
        </Text>
      </Flex>

      <Flex gap={1}>
        {priorityOptions.map((priority) => (
          <Chip
            key={priority}
            onClick={() => onChange(priority)}
            variant='outlined'
            sx={{
              height: 32,
              py: 2,
              px: 1,
              '& .MuiChip-label': {
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                pl: 0.5,
                pr: 0.5,
              },
              bgcolor: getBackgroundColor(priority),
              borderColor: getBorderColor(priority),
              color: 'text.primary',
              '&:hover': {
                bgcolor: getHoverBackgroundColor(priority),
              },
            }}
            label={
              <>
                <Box
                  component='span'
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    bgcolor:
                      TaskTemplateProperties.getColorForPriority(priority),
                    display: 'inline-block',
                  }}
                />
                <Text variant='body1'>{capitalize(priority)}</Text>
              </>
            }
          />
        ))}
      </Flex>

      {error && (
        <Text color='error' fontSize='body2' mt={1}>
          {error.message}
        </Text>
      )}
    </Box>
  )
}
