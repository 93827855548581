import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query'
import { tasksKeys } from '@core/react-query/features/tasks/tasks'
import { useMutateQueryData } from '@core/react-query/hooks'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { taskKeys, RequestTypes } from '@core/react-query/features/tasks/task'
import { ITask } from '@core/entities/task/Task'
import { OfflineEntityType } from '@core/react-query/defaultMutations'

export const getCreateTaskDraftMutationOptions = (queryClient: QueryClient) => {
  const { updateInfiniteQueryItem } = useMutateQueryData(queryClient)
  return {
    ...taskKeys.createTaskDraft,
    meta: {
      name: 'Create Task Draft',
      offlineEnabled: true,
      variablesType: OfflineEntityType.TASK_SUBMISSION,
    },
    onSuccess: (draft) => {
      updateInfiniteQueryItem(
        tasksKeys.draftTasks({
          organisationId: draft.template.organisationId,
          projectIds: [draft.projectId],
        }).queryKey,
        draft,
      )
    },
  }
}

export const useCreateTaskDraft = () => {
  const queryClient = useQueryClient()
  return useMutation<ITask, RequestErrorType, RequestTypes['createTaskDraft']>(
    getCreateTaskDraftMutationOptions(queryClient),
  )
}
