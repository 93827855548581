import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { taskTemplateKeys } from '../taskTemplateKeys'
import { RequestTypes } from '../requestTypes'
import { TaskTemplate } from '@core/entities/task/TaskTemplate'

export const useCreateTaskTemplate = (
  options?: UseMutationOptions<
    TaskTemplate,
    string,
    RequestTypes['createTaskTemplate']
  >,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    ...taskTemplateKeys.createTaskTemplate,
    retry: 0,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: taskTemplateKeys.byOrganisation({
          organisationId: variables.organisationId,
        }).queryKey,
      })
    },
    ...options,
  })
}
