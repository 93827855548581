import React from 'react'
import {
  RegisterItem,
  RegisterItemInspectionProperty,
} from '@core/entities/register/RegisterItem'
import { RegisterItemContentVariant } from '@modules/registers/items/types'
import { Flex, Text, Box } from '@fundamentals'
import { DropdownButton, IconButton, Tooltip } from '@common/material'
import { PageUrls } from '@core/page-urls'
import { localizedStrings } from '@core/strings'
import moment from 'moment'
import {
  AddCircleOutlineOutlined,
  FactCheckOutlined,
  MoreVert,
  OpenInNewOutlined,
  Warning,
  Cancel,
  CheckCircle,
  DoNotDisturbOn,
} from '@mui/icons-material'
import { useModal } from '@common/GlobalModal'
import { NewInspectionModal } from '../modals/NewInspectionModal'
import { useRegisterContext } from '../details/RegisterContext'
import { useRouter } from 'next/router'
import { RegisterItemContentButtons } from './RegisterItemPropertyValueButtons'
import { DropdownButtonOption } from '@common/material/DropdownButton/types'

type RegisterItemPropertyInspectionValueProps = {
  registerItemPropertyData: RegisterItemInspectionProperty<string>
  registerItem?: RegisterItem
  variant?: RegisterItemContentVariant
}

export const RegisterItemPropertyInspectionValue: React.FC<
  RegisterItemPropertyInspectionValueProps
> = ({ registerItemPropertyData, registerItem, variant }) => {
  const { scope, register } = useRegisterContext()
  const router = useRouter()
  const { showModal } = useModal()

  const inspections = (registerItem?.data?.filter(
    (item) => item.type === 'INSPECTION',
  ) ?? []) as RegisterItemInspectionProperty<string>[]

  const inspection = registerItemPropertyData?.inspection

  const lastInspected = moment(
    registerItemPropertyData?.inspection?.lastInspectionTime,
  ).format('DD MMM YYYY HH:mm')

  const hasNextInspection = !!inspection?.nextInspectionDue
  const nextInspectionDue = moment(inspection?.nextInspectionDue).format(
    'DD MMM YYYY HH:mm',
  )
  const lastInspectedBy = registerItemPropertyData?.inspection?.lastInspectedBy

  const hasInspectionExpired = moment().isAfter(inspection?.nextInspectionDue)

  const getInspectionDueColor = (dueDate: string | undefined): string => {
    if (!dueDate) return 'inherit'
    const now = moment()
    const due = moment(dueDate)

    if (now.isAfter(due)) return 'error'
    if (due.diff(now, 'days') < 2) return 'warning.main'
    return 'inherit'
  }

  if (variant === 'table') {
    return (
      <Flex fontSize='0.75rem' flexDirection='column' gap={0.25}>
        <Flex alignItems='center' gap={0.5}>
          {!lastInspectedBy ? (
            <Box data-test='inspection-not-done'>
              <DoNotDisturbOn
                sx={{ fontSize: '0.9rem', color: 'warning.main' }}
              />
            </Box>
          ) : hasInspectionExpired ? (
            <Box data-test='inspection-expired-icon'>
              <Cancel sx={{ fontSize: '0.9rem' }} color='error' />
            </Box>
          ) : (
            <Box data-test='inspection-success-icon'>
              <CheckCircle sx={{ fontSize: '0.9rem' }} color='success' />
            </Box>
          )}
          <Text fontWeight={500} color='text.secondary' fontSize='0.7rem'>
            {registerItemPropertyData?.name}
          </Text>
        </Flex>
        <Flex alignItems='center' gap={0.5}>
          <Text color='text.secondary' fontSize='0.7rem'>
            {localizedStrings.lastCompleted}:
          </Text>
          <Text fontSize='0.65rem'>
            {lastInspectedBy ? lastInspected : localizedStrings.never}
          </Text>
        </Flex>
        {lastInspectedBy && (
          <Flex alignItems='center' gap={0.5}>
            <Text color='text.secondary' fontSize='0.7rem'>
              {localizedStrings.completedBy}:
            </Text>
            <Text fontSize='0.65rem'>
              {lastInspectedBy.firstName} {lastInspectedBy.lastName}
            </Text>
          </Flex>
        )}
        {hasNextInspection && (
          <Flex alignItems='center' gap={0.5}>
            <Text color='text.secondary' fontSize='0.7rem'>
              {localizedStrings.nextInspectionDue}:
            </Text>
            <Text
              color={getInspectionDueColor(inspection?.nextInspectionDue)}
              fontSize='0.65rem'
            >
              {nextInspectionDue}
            </Text>
          </Flex>
        )}
      </Flex>
    )
  }

  if (!lastInspectedBy)
    return (
      <Flex alignItems='center'>
        <Text width='10rem'>---</Text>
        <IconButton
          color='primary'
          sx={{ height: 'fit-content', alignSelf: 'center' }}
          data-test='new-inspection-button'
          onClick={() => {
            const isOnlyOneInspectionAndTemplate =
              inspections.length === 1 &&
              inspections[0]?.inspection?.checklistTemplates?.length === 1

            if (isOnlyOneInspectionAndTemplate) {
              router.push({
                pathname: PageUrls.newChecklistProject({
                  organisationId: scope.organisationId,
                  projectId: register.projectId,
                }),
                query: {
                  registerId: register?.id,
                  registerItemId: registerItem.id,
                  projectId: register.projectId,
                  inspectionId: inspections[0].inspection.inspectionId,
                  inspectionTemplateId:
                    inspections[0].inspection.checklistTemplates[0].id,
                },
              })
            } else {
              showModal(
                <NewInspectionModal
                  organisationId={scope.organisationId}
                  projectId={register.projectId}
                  registerId={register.id}
                  registerItem={registerItem}
                  inspectionId={inspection.inspectionId}
                  inspections={inspections}
                />,
              )
            }
          }}
        >
          <AddCircleOutlineOutlined />
        </IconButton>
      </Flex>
    )

  const options: DropdownButtonOption[] = []

  if (!!register?.projectId && !!inspections?.length) {
    options.push({
      title: localizedStrings.newInspection,
      'data-test': 'new-inspection-button',
      icon: <FactCheckOutlined />,
      onSelect: () => {
        const isOnlyOneInspectionAndTemplate =
          inspections.length === 1 &&
          inspections[0]?.inspection?.checklistTemplates?.length === 1

        if (isOnlyOneInspectionAndTemplate) {
          router.push({
            pathname: PageUrls.newChecklistProject({
              organisationId: scope.organisationId,
              projectId: register.projectId,
            }),
            query: {
              registerId: register.id,
              registerItemId: registerItem.id,
              projectId: register.projectId,
              inspectionId: inspections[0].inspection.inspectionId,
              inspectionTemplateId:
                inspections[0].inspection.checklistTemplates[0].id,
            },
          })
        } else {
          showModal(
            <NewInspectionModal
              organisationId={scope.organisationId}
              projectId={register.projectId}
              registerId={register.id}
              registerItem={registerItem}
              inspections={inspections}
              inspectionId={inspection.inspectionId}
            />,
          )
        }
      },
    })
  }

  options.push({
    title: localizedStrings.openChecklist,
    icon: <OpenInNewOutlined />,
    onSelect: () => {
      const checklist = inspection?.lastSubmittedChecklist
      const url = PageUrls.projectChecklistDetail({
        organisationId: checklist.organisationId,
        projectId: checklist.project.id,
        checklistId: checklist.id,
      })

      window.open(url, '_blank', 'noopener, noreferrer')
    },
  })

  return (
    <RegisterItemContentButtons
      variant={variant}
      value={localizedStrings.inspection}
      registerItemPropertyData={registerItemPropertyData}
      options={
        <Tooltip title={localizedStrings.options} arrow>
          <DropdownButton
            label={localizedStrings.options}
            options={options}
            renderButton={(onClick) => (
              <IconButton onClick={onClick}>
                <MoreVert />
              </IconButton>
            )}
          />
        </Tooltip>
      }
    >
      <Flex gap={4}>
        <Flex flexDirection='column'>
          <Text noWrap fontSize={12} color='text.disabled'>
            {localizedStrings.lastCompleted}
          </Text>
          <Text noWrap fontSize={14}>
            {lastInspected}
          </Text>
          {lastInspectedBy && (
            <Text
              width={165}
              noWrap
              fontSize={14}
              color='text.secondary'
            >{`${lastInspectedBy.firstName} ${lastInspectedBy.lastName}`}</Text>
          )}
        </Flex>

        {hasNextInspection && (
          <Flex flexDirection='column' width={155}>
            <Text
              noWrap
              fontSize={12}
              color={hasInspectionExpired ? 'error' : 'text.disabled'}
            >
              {localizedStrings.nextInspectionDue}
            </Text>
            <Flex gap={1}>
              {hasInspectionExpired && (
                <Warning fontSize='small' color='error' />
              )}
              <Text
                noWrap
                fontSize={14}
                color={getInspectionDueColor(inspection?.nextInspectionDue)}
              >
                {nextInspectionDue}
              </Text>
            </Flex>
          </Flex>
        )}

        <IconButton
          color='primary'
          sx={{ height: 'fit-content', alignSelf: 'center' }}
          onClick={() => {
            const checklist = inspection?.lastSubmittedChecklist
            const url = PageUrls.projectChecklistDetail({
              organisationId: checklist.organisationId,
              projectId: checklist.project.id,
              checklistId: checklist.id,
            })

            window.open(url, '_blank', 'noopener, noreferrer')
          }}
        >
          <OpenInNewOutlined />
        </IconButton>
      </Flex>
    </RegisterItemContentButtons>
  )
}
