import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RequestTypes } from '../requestTypes'
import { TaskTemplateVersion } from '@core/entities/task/TaskTemplateVersion'
import { taskTemplateKeys } from '@core/react-query/features/tasks/templates'

export const useGetTaskTemplateVersion = (
  payload: RequestTypes['getTaskTemplateVersion'],
  options?: UseQueryOptions<TaskTemplateVersion, string>,
) => {
  return useQuery({
    select: TaskTemplateVersion.new,
    ...taskTemplateKeys.getTaskTemplateVersion(payload),
    ...options,
  })
}
