import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { ITask } from '@core/entities/task/Task'
import {
  uploadProofs,
  uploadTemplateAnswerFiles,
} from '@core/react-query/features/permits/permit/helpers'
import { RequestTypes } from './requestTypes'
import { createQueryKeyString } from '@core/react-query/queryParams'

const taskQueryKeys = createQueryKeys('task', {
  byId: ({ taskId }: RequestTypes['getTask']) => {
    return {
      queryKey: [taskId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(`tasks/${taskId}`)

        return data as ITask
      },
      contextQueries: {
        timeline: {
          queryKey: null,
          queryFn: async () => {
            const { data } = await APIAxiosInstance.get(
              `audits/tasks/${taskId}`,
            )

            return data
          },
        },
      },
    }
  },

  assignableUsers: ({
    projectId,
    templateId,
    stepId,
    term,
  }: RequestTypes['getTaskAssignableUsers']) => {
    return {
      queryKey: [projectId, templateId, stepId, term],
      queryFn: async () => {
        const queryParams = createQueryKeyString({
          projectId,
          templateId,
          stepId,
          term,
        })

        const { data } = await APIAxiosInstance.get(
          `tasks/assignable-users${queryParams}`,
        )

        return data
      },
    }
  },
})

const taskMutationKeys = createMutationKeys('task', {
  createTask: {
    mutationKey: null,
    mutationFn: async ({
      projectId,
      stepAnswers,
      attachments,
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['createTask']) => {
      console.log('mutation', mutation)

      let uploadedAttachments
      if (attachments) {
        uploadedAttachments = await uploadProofs(
          attachments,
          `${mutation.taskTemplateId}-task-attachments`,
        )
      }

      const uploadedAnswers = await uploadTemplateAnswerFiles({
        answers: stepAnswers.answers,
        templateId: mutation.taskTemplateId,
      })

      const { data } = await APIAxiosInstance.post(
        `tasks?projectId=${projectId}`,
        {
          ...mutation,
          stepAnswers: { answers: uploadedAnswers },
          attachments: { data: uploadedAttachments },
        },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )
      return data as ITask
    },
  },
  createTaskDraft: {
    mutationKey: null,
    mutationFn: async ({
      organisationId,
      stepAnswers,
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['createTaskDraft']) => {
      const uploadedAnswers = await uploadTemplateAnswerFiles({
        answers: stepAnswers.answers,
        templateId: mutation.taskTemplateId,
      })

      const { data } = await APIAxiosInstance.post(
        `tasks/drafts?organisationId=${organisationId}`,
        { ...mutation, stepAnswers: { answers: uploadedAnswers } },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )

      return data as ITask
    },
  },
  updateTaskDraft: {
    mutationKey: null,
    mutationFn: async ({
      id,
      stepAnswers,
      offlineSubmittedOn,
      ...mutation
    }: RequestTypes['updateTaskDraft']) => {
      const uploadedAnswers = await uploadTemplateAnswerFiles({
        answers: stepAnswers.answers,
        templateId: mutation.taskTemplateId,
      })

      const { data } = await APIAxiosInstance.put(
        `tasks/drafts/${id}`,
        {
          ...mutation,
          stepAnswers: { answers: uploadedAnswers },
        },
        { headers: { 'X-OFFLINE-SUBMITTED-ON': offlineSubmittedOn } },
      )

      return data as ITask
    },
  },
  editTask: {
    mutationKey: null,
    mutationFn: async ({ id, ...payload }: RequestTypes['editTask']) => {
      const { data } = await APIAxiosInstance.put(`tasks/${id}`, payload)

      return data
    },
  },

  cancelTask: {
    mutationKey: null,
    mutationFn: async ({ id }: RequestTypes['cancelTask']) => {
      const { data } = await APIAxiosInstance.post(`tasks/${id}/cancel`)

      return data
    },
  },

  reopenTask: {
    mutationKey: null,
    mutationFn: async ({
      id,
      currentStepId,
      assigneeUserId,
    }: RequestTypes['reopenTask']) => {
      const { data } = await APIAxiosInstance.post(`tasks/${id}/reopen`, {
        currentStepId,
        assigneeUserId,
      })

      return data
    },
  },

  reassignTask: {
    mutationKey: null,
    mutationFn: async ({
      id,
      assigneeUserId,
      notes,
    }: RequestTypes['reassignTask']) => {
      const { data } = await APIAxiosInstance.post(`tasks/${id}/reassign`, {
        assigneeUserId,
        notes,
      })

      return data
    },
  },

  requestChangesTask: {
    mutationKey: null,
    mutationFn: async ({
      id,
      assigneeUserId,
      comment,
    }: RequestTypes['requestChangesTask']) => {
      const { data } = await APIAxiosInstance.post(
        `tasks/${id}/request-changes`,
        {
          assigneeUserId,
          comment,
        },
      )

      return data
    },
  },

  submitTaskStep: {
    mutationKey: null,
    mutationFn: async ({
      id,
      taskTemplateId,
      assigneeUserId,
      stepAnswers,
    }: RequestTypes['submitTaskStep']) => {
      const uploadedAnswers = await uploadTemplateAnswerFiles({
        answers: stepAnswers.answers,
        templateId: taskTemplateId,
      })

      const { data } = await APIAxiosInstance.post(`tasks/${id}/submit-step`, {
        assigneeUserId,
        stepAnswers: { answers: uploadedAnswers },
      })

      return data
    },
  },
})

const taskKeys = mergeQueryKeys(taskQueryKeys, taskMutationKeys).task

export { taskKeys }
