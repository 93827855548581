import { localizedStrings } from '@core/strings'
import { ITemplateQuestion } from '@core/entities/template/TemplateQuestion'
import Utils from '@core/utils/utils'
import moment from 'moment'

export const getTemplateEndDateRules = (startsOn: string, template) => ({
  required: 'Please select an end date',
  validate: (value) => {
    if (startsOn && template) {
      const startPlus30 = moment(startsOn).add(30, 'm')
      if (
        !template.calculateDurationBasedOnDays &&
        moment(value).diff(startPlus30) < 0
      ) {
        return 'Please select a date at least 30 minutes after the start date'
      }

      if (
        !template.maxDurationSeconds &&
        !template.calculateDurationBasedOnDays
      ) {
        return true
      }

      if (template.calculateDurationBasedOnDays) {
        const dateDiff = moment(value).diff(moment(startsOn), 'days', true)

        return (
          dateDiff <= template.maxDurationDays ||
          `This permit has a maximum duration of ${template.maxDurationDays} days`
        )
      } else {
        const maxDuration = Utils.secondsToDhm(template.maxDurationSeconds)
        const dateDiff = moment(value).diff(moment(startsOn), 'seconds')
        return (
          dateDiff <= template.maxDurationSeconds ||
          `This permit has a maximum duration of ${maxDuration.days} days, ${maxDuration.hours} hours and ${maxDuration.minutes} minutes`
        )
      }
    }
    return true
  },
})

export const AlphaNumericQuestionRules = {
  validate: ({ submissionText, required }: ITemplateQuestion) => {
    return (
      !required || (required && !!submissionText) || 'This field is required'
    )
  },
}

export const DateQuestionRules = {
  validate: ({ submissionText, required }: ITemplateQuestion) => {
    return (
      !required || (required && !!submissionText) || 'This field is required'
    )
  },
}

export const MultipleChoiceQuestionRules = {
  validate: ({ answers, minAnswers, maxAnswers }: ITemplateQuestion) => {
    const selectedAnswers = answers?.filter((v) => v.selected)
    if (selectedAnswers.length > maxAnswers) {
      return 'Too many selected'
    } else if (selectedAnswers.length < minAnswers) {
      return 'Too few selected'
    } else if (
      selectedAnswers.some(
        ({ selected, requiresSubmissionText, submissionText }) =>
          selected && requiresSubmissionText && !submissionText,
      )
    ) {
      return 'Missing some additional inputs'
    }
    return true
  },
}

export const NumericQuestionRules = {
  validate: ({ submissionText, required }: ITemplateQuestion) => {
    return (
      !required || (required && !!submissionText) || 'This field is required'
    )
  },
}

export const DocumentQuestionRules = {
  validate: ({ submissionDocuments, required }: ITemplateQuestion) => {
    if (required && !submissionDocuments?.length) {
      return 'Requires proof'
    }

    return true
  },
}

export const PreconditionQuestionRules = {
  validate: ({
    submissionText,
    invalidAnswer,
    invalidDescription,
  }: ITemplateQuestion) => {
    if (submissionText === invalidAnswer) return invalidDescription
    return !!submissionText || 'This field is required'
  },
}

export const AcknowledgementQuestionRules = {
  validate: ({ acknowledged }: ITemplateQuestion) => {
    return acknowledged || localizedStrings.acknowledgeQuestionPrompt
  },
}

export const LinkPermitsQuestionRules = {
  validate: ({ linkedPermitsIds, required }: ITemplateQuestion) =>
    !required ||
    (required && !!linkedPermitsIds && linkedPermitsIds?.length > 0) ||
    'This field is required',
}

export const LinkChecklistsQuestionRules = {
  validate: ({ linkedChecklistsIds, required }: ITemplateQuestion) =>
    !required ||
    (required && !!linkedChecklistsIds && linkedChecklistsIds?.length > 0) ||
    'This field is required',
}

export const MAX_SUMMARY_CHARACTERS = 150

export const CountdownQuestionRules = {
  validate: ({ countdownDuration, required }: ITemplateQuestion) => {
    return (
      !required || (required && !!countdownDuration) || 'This field is required'
    )
  },
}
