import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { taskKeys, RequestTypes } from '@core/react-query/features/tasks/task'
import {
  ITaskAssignableUser,
  TaskAssignableUser,
} from '@core/entities/task/TaskAssignableUser'
import { PageResponse } from '@core/entities/pagination'
import { RequestErrorType } from '@core/utils/api-axios-instance'
import { usePageableQuery } from '@core/react-query/hooks'

const dataTransformer = (response: PageResponse<ITaskAssignableUser>) => {
  return {
    ...response,
    content: response.content.map((taskAssignableUser) =>
      TaskAssignableUser.new(taskAssignableUser),
    ),
  }
}

export const useGetTaskAssignableUsers = (
  payload: RequestTypes['getTaskAssignableUsers'],
  options?: UseInfiniteQueryOptions<
    PageResponse<ITaskAssignableUser>,
    RequestErrorType,
    PageResponse<TaskAssignableUser>
  >,
) => {
  const taskAssignableUsersQuery = taskKeys.assignableUsers(payload)
  const infiniteQueryOptions = usePageableQuery({
    queryEnabled: options?.enabled,
    queryKey: taskAssignableUsersQuery.queryKey,
    dataTransformer,
  })

  return useInfiniteQuery<
    PageResponse<ITaskAssignableUser>,
    RequestErrorType,
    PageResponse<TaskAssignableUser>
  >({
    ...infiniteQueryOptions,
    ...taskAssignableUsersQuery,
    ...options,
  })
}
