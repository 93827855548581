import { localizedStrings } from '@core/strings'
import React, { useCallback, useMemo } from 'react'
import { Button, DropdownButton } from '@common/material'
import {
  ArrowDropDown,
  CalendarTodayOutlined,
  CancelOutlined,
  CheckCircleOutline,
  Download,
  FileCopy,
  PauseCircleOutline,
  PlayCircleOutline,
  SyncAlt,
  QrCode2,
  Schedule,
  InfoOutlined,
} from '@mui/icons-material'
import {
  PermitDetailsActionsType,
  ResumePermitSubmitData,
  SuspendPermitSubmitData,
} from '@modules/permitDetails/types'
import { TransferPermitModal } from '@modules/permitDetails/modals/TransferPermitModal'
import { CancelPermitModal } from '@modules/permitDetails/modals/CancelPermitModal'
import { RequestExtensionModal } from '@modules/permitDetails/modals/RequestExtensionModal'
import { ClosePermitModal } from '@modules/permitDetails/modals/ClosePermitModal'
import { useTheme } from '@mui/material/styles'
import { PageUrls } from '@core/page-urls'
import { PermitListTabs } from '@core/entities/permit/PermitBase'
import { useModal } from '@common/GlobalModal'
import { useRouter } from 'next/router'
import { useNavigationContext } from '@common/hooks'
import { PermitQRCodeModal } from '@modules/permitDetails/modals/PermitQRCodeModal'
import { SuspendResumePermitModal } from '@modules/permitDetails/modals/SuspendResumePermitModal'
import {
  useClonePermit,
  usePermitPdfDownloadUrl,
  useRequestResumePermit,
  useResumePermit,
  useSignOffPermit,
  useSuspendPermit,
} from '@core/react-query/features/permits/permit'
import { ShortenPermitModal } from '@modules/permitDetails/modals/ShortenPermitModal'
import { Permit } from '@core/entities/permit/Permit'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import { useGetProfile } from '@core/react-query/features/profile'
import { BetaPermitModal } from '@modules/permitDetails/modals/BetaPermitModal'
import { useToast } from '@core/toast'
import { PostPermitChecksSubmitModal } from '../modals/PostPermitChecksSubmitModal'

const SecondaryActions: React.FC<{ permit: Permit }> = ({ permit }) => {
  const theme = useTheme()
  const router = useRouter()
  const { organisationId, projectId } = useNavigationContext()
  const { showModal, hideModal } = useModal()
  const pdfMutation = usePermitPdfDownloadUrl({
    onError: (e) => toast.error(e.message),
  })
  const clonePermit = useClonePermit()
  const suspendPermit = useSuspendPermit()
  const resumePermit = useResumePermit()
  const requestResumePermit = useRequestResumePermit()
  const signOffPermit = useSignOffPermit()
  const { data: profile } = useGetProfile()

  const { flags } = useFeatureFlags(['beta'])
  const toast = useToast()

  const handleClone = useCallback(() => {
    clonePermit.mutate(
      {
        permitId: permit.id,
      },
      {
        onSuccess: () => {
          toast.success(localizedStrings.permitCloned)
          router.push(
            PageUrls.permits(organisationId, {
              tab: PermitListTabs.DRAFTS,
            }),
          )
        },
        onError: (e) => toast.error(e.message),
      },
    )
  }, [organisationId, permit.id, router])

  const handleDownload = useCallback(async () => {
    const pdfDownloadUrl = await pdfMutation.mutateAsync({
      permitId: permit.id,
    })

    const toastId = Utils.GUID()
    toast.progress(localizedStrings.downloadingPermit, {
      duration: 80 * 1000,
      id: toastId,
    })

    const fileGenerated = await Utils.pollForSuccessfulRequest(
      pdfDownloadUrl.urlDownload,
    )

    if (!fileGenerated) {
      toast.error(localizedStrings.permitDownloadFailed, { id: toastId })
    } else {
      window.location.assign(pdfDownloadUrl.urlDownload)
      toast.success(localizedStrings.permitDownloadComplete, { id: toastId })
    }
  }, [permit.id])

  const handleResumePermit = (data: ResumePermitSubmitData) => {
    const canResumePermit = permit?.hasPermission('SUSPENSION_RESUME')
    const action = canResumePermit ? resumePermit : requestResumePermit
    const message = canResumePermit
      ? localizedStrings.permitResumed
      : localizedStrings.resumePermitRequested

    action.mutate(
      {
        permitId: permit.id,
        comment: data.comment,
        attachmentsKeys: data.attachmentsKeys,
      },
      {
        onSuccess: () => {
          hideModal()
          toast.success(message)
        },
        onError: (e) => toast.error(e.message),
      },
    )
  }

  const handleSuspendPermit = (data: SuspendPermitSubmitData) => {
    suspendPermit.mutate(
      {
        permitId: permit.id,
        comment: data.comment,
        attachmentsKeys: data.attachmentsKeys,
        suspensionReasonCategory: data.suspensionReasonCategory,
      },
      {
        onSuccess: () => {
          hideModal()
          toast.success(localizedStrings.permitSuspended)
        },
        onError: (e) => toast.error(e.message),
      },
    )
  }

  const secondaryActionsOptionsMemoised = useMemo(() => {
    const secondaryActionsOptions: PermitDetailsActionsType[] = []
    const transferAction: PermitDetailsActionsType = {
      name: localizedStrings.transferPermit,
      icon: <SyncAlt />,
      dataTest: 'transfer-permit',
      onClick: () => {
        showModal(<TransferPermitModal permit={permit} />)
      },
    }
    const cancelAction: PermitDetailsActionsType = {
      name: localizedStrings.cancelPermit,
      dataTest: 'cancel-permit',
      icon: <CancelOutlined />,
      onClick: () => {
        showModal(<CancelPermitModal permitId={permit.id} />)
      },
    }
    const downloadAction: PermitDetailsActionsType = {
      name: localizedStrings.downloadPermit,
      icon: <Download />,
      dataTest: 'downloadPermit',
      onClick: () => handleDownload(),
    }
    const cloneAction: PermitDetailsActionsType = {
      name: localizedStrings.clone,
      icon: <FileCopy />,
      dataTest: 'clone-permit',
      onClick: () => handleClone(),
    }
    const requestExtension: PermitDetailsActionsType = {
      name: localizedStrings.requestExtension,
      icon: <CalendarTodayOutlined />,
      dataTest: 'request-extension-permit',
      onClick: () => showModal(<RequestExtensionModal permit={permit} />),
    }
    const closePermit: PermitDetailsActionsType = {
      name: localizedStrings.closePermit,
      icon: <CheckCircleOutline />,
      dataTest: 'close-permit',
      onClick: () => showModal(<ClosePermitModal permit={permit} />),
    }
    const postPermitChecks: PermitDetailsActionsType = {
      name: localizedStrings.closePermit,
      icon: <CheckCircleOutline />,
      dataTest: 'post-permit-checks',
      disabled: permit.template.requireGeolocationForPostPermitChecks,
      tooltipTitle: permit.template.requireGeolocationForPostPermitChecks
        ? localizedStrings.postPermitChecksGeolocationWebTooltip
        : undefined,
      onClick: () => {
        if (!permit?.template?.postPermitChecksQuestions?.questions?.length) {
          return showModal(
            <PostPermitChecksSubmitModal
              permitId={permit.id}
              templateId={permit.template.id}
            />,
          )
        }

        if (projectId) {
          router.push(
            PageUrls.projectPermitSignoffCheck({
              organisationId,
              projectId,
              permitId: permit.id,
            }),
          )
        } else {
          router.push(
            PageUrls.permitSignoffCheck({
              organisationId,
              permitId: permit.id,
            }),
          )
        }
      },
    }
    const suspendPermit: PermitDetailsActionsType = {
      name: localizedStrings.suspendPermit,
      icon: <PauseCircleOutline />,
      dataTest: 'suspend-permit',
      onClick: () =>
        showModal(
          <SuspendResumePermitModal
            permit={permit}
            onSubmit={handleSuspendPermit}
          />,
        ),
    }
    const resumePermit: PermitDetailsActionsType = {
      name: localizedStrings.resumePermit,
      icon: <PlayCircleOutline />,
      dataTest: 'resume-permit',
      onClick: () =>
        showModal(
          <SuspendResumePermitModal
            permit={permit}
            onSubmit={handleResumePermit}
          />,
        ),
    }

    const shortenPermit: PermitDetailsActionsType = {
      name: localizedStrings.shortenPermit,
      icon: <Schedule />,
      dataTest: 'shorten-permit',
      onClick: () => showModal(<ShortenPermitModal permit={permit} />),
    }

    secondaryActionsOptions.push({
      name: localizedStrings.showQRCode,
      icon: <QrCode2 />,
      dataTest: 'qr-code',
      onClick: () => {
        showModal(
          <PermitQRCodeModal permit={permit} organisationId={organisationId} />,
        )
      },
    })

    const beta: PermitDetailsActionsType = {
      name: 'Beta',
      icon: <InfoOutlined />,
      dataTest: 'beta',
      onClick: () => {
        showModal(<BetaPermitModal permit={permit} />)
      },
    }

    const isPermitSuspended = permit.status === 'SUSPENDED'
    const showSuspendPermit =
      permit?.hasPermission('SUSPEND') &&
      ['ISSUED', 'OPEN', 'EXTENSION_REQUESTED'].includes(permit.status)
    const showResumePermit =
      isPermitSuspended &&
      (permit?.hasPermission('REQUEST_SUSPENSION_RESUME') ||
        permit?.hasPermission('SUSPENSION_RESUME'))
    const showPostPermitChecks =
      !permit.countdownExpiry &&
      permit?.hasPermission('SUBMIT_POST_PERMIT_CHECKS') &&
      !isPermitSuspended
    const showClosePermit =
      permit?.hasPermission('DECLARE_WORK_COMPLETE') && !isPermitSuspended
    const showRequestExtension =
      permit?.hasPermission('REQUEST_EXTENSION') && !isPermitSuspended
    const showTransferAction =
      permit?.hasPermission('INITIATE_TRANSFER') && !isPermitSuspended

    showPostPermitChecks && secondaryActionsOptions.push(postPermitChecks)
    showClosePermit && secondaryActionsOptions.push(closePermit)
    showRequestExtension && secondaryActionsOptions.push(requestExtension)
    showTransferAction && secondaryActionsOptions.push(transferAction)

    if (permit?.hasPermission('CANCEL')) {
      secondaryActionsOptions.push(cancelAction)
    }

    secondaryActionsOptions.push(downloadAction)
    permit?.hasPermission('CLONE') && secondaryActionsOptions.push(cloneAction)
    showSuspendPermit && secondaryActionsOptions.push(suspendPermit)
    showResumePermit && secondaryActionsOptions.push(resumePermit)
    permit?.hasPermission('SHORTEN') &&
      secondaryActionsOptions.push(shortenPermit)
    flags.beta && secondaryActionsOptions.push(beta)

    return secondaryActionsOptions
  }, [
    permit.template.requireGeolocationForPostPermitChecks,
    permit.countdownExpiry,
    showModal,
    permit,
    handleDownload,
    handleClone,
  ])

  return (
    <DropdownButton
      label={localizedStrings.actions}
      options={secondaryActionsOptionsMemoised.map((secondaryOptions) => ({
        title: secondaryOptions.name,
        value: secondaryOptions.name,
        'data-test': secondaryOptions.dataTest,
        onSelect: secondaryOptions.onClick,
        disabled: secondaryOptions.disabled,
        tooltipTitle: secondaryOptions.tooltipTitle,
        icon: secondaryOptions.icon,
      }))}
      renderButton={(onClick) => (
        <Button
          variant='outlined'
          data-test='secondary-permit-actions'
          endIcon={<ArrowDropDown />}
          onClick={onClick}
          sx={{
            fontSize: 16,
            background: theme.palette.legacy.white[10],
            mr: 1,
          }}
        >
          {localizedStrings.actions}
        </Button>
      )}
    />
  )
}

export default SecondaryActions
