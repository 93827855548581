import { localizedStrings } from '@core/strings'
import React from 'react'
import { MenuItem, TextField } from '@common/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { TaskTemplateStep } from '@core/entities/task/TaskTemplateStep'

export type TaskStepPickerProps = TextFieldProps & {
  steps: Array<TaskTemplateStep>
  value: TaskTemplateStep | null
  onChange?: (step: TaskTemplateStep) => void
}

const TaskStepPicker: React.FC<TaskStepPickerProps> = ({
  steps,
  value,
  onChange,
  ...props
}) => {
  const findStepById = (id: string) =>
    steps?.find((step) => step.id.toString() === id)

  return (
    <TextField
      value={value?.id ?? ''}
      label={localizedStrings.moveTo}
      data-test='select-step-dropdown-btn'
      placeholder={localizedStrings.moveTo}
      onChange={({ target: { value: stepId } }) => {
        onChange(findStepById(stepId))
      }}
      select
      fullWidth
      required
      {...props}
    >
      {steps?.map((step, index) => (
        <MenuItem
          key={step.id}
          value={step.id}
          data-test={`select-step-dropdown-option-${index}`}
        >
          {step.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export { TaskStepPicker }
