import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'
import { APIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { RequestTypes } from './requestTypes'
import { createQueryKeyString } from '@core/react-query/queryParams'
import { ITaskTemplate } from '@core/entities/task/TaskTemplate'
import { ITaskTemplateVersion } from '@core/entities/task/TaskTemplateVersion'
import { PageResponse } from '@core/entities/pagination'

const taskTemplateQueryKeys = createQueryKeys('task-templates', {
  // ========================== Task Template ==========================
  byOrganisation: ({
    organisationId,
    projectIds,
    status,
    term,
    page,
    size,
    sort,
    excludeExistingProjectAssignments,
  }: RequestTypes['getTaskTemplates']) => {
    return {
      queryKey: [
        organisationId,
        projectIds,
        status,
        term,
        page,
        size,
        sort,
        excludeExistingProjectAssignments,
      ],
      queryFn: async () => {
        const queryString = createQueryKeyString({
          organisationId,
          projectIds,
          status,
          term,
          page,
          size,
          excludeExistingProjectAssignments,
          sort,
        })

        const { data } = await APIAxiosInstance.get(
          `/tasks/admin/templates${queryString}`,
          {},
        )
        return data as PageResponse<ITaskTemplate>
      },
    }
  },

  getTaskTemplate: ({ templateId }: RequestTypes['getTaskTemplate']) => {
    return {
      queryKey: [templateId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `/tasks/admin/templates/${templateId}`,
          {},
        )
        return data
      },
    }
  },

  getAvailableTemplates: ({
    projectId,
  }: RequestTypes['getAvailableTemplates']) => {
    return {
      queryKey: [projectId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `tasks/templates/create-list`,
          { params: { projectId } },
        )
        return data
      },
    }
  },

  // ========================== Task Template Version ==========================
  getTaskTemplateVersions: (
    payload: RequestTypes['getTaskTemplateVersions'],
  ) => {
    return {
      queryKey: [payload.templateId, payload.page, payload.size],
      queryFn: async () => {
        const queryString = createQueryKeyString({
          page: payload.page,
          size: payload.size,
        })
        const { data } = await APIAxiosInstance.get(
          `tasks/admin/templates/${payload.templateId}/versions${queryString}`,
        )
        return data as PageResponse<ITaskTemplateVersion>
      },
    }
  },
  getTaskTemplateVersion: (payload: RequestTypes['getTaskTemplateVersion']) => {
    return {
      queryKey: [payload.templateId, payload.versionId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `tasks/admin/templates/${payload.templateId}/versions/${payload.versionId}`,
        )
        return data as ITaskTemplateVersion
      },
    }
  },

  // ========================== Task Template Role ==========================
  getTaskTemplateRoles: (payload: RequestTypes['getTaskTemplateRoles']) => {
    return {
      queryKey: [payload.templateId, payload.versionId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `tasks/admin/templates/${payload.templateId}/versions/${payload.versionId}/roles`,
        )
        return data
      },
    }
  },
})

const taskTemplateMutationKeys = createMutationKeys('task-templates', {
  // ========================== Task Template Version ==========================
  createTaskTemplate: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['createTaskTemplate']) => {
      const queryString = createQueryKeyString({
        organisationId: payload.organisationId,
      })
      const { data } = await APIAxiosInstance.post(
        `/tasks/admin/templates${queryString}`,
        {
          ...payload,
        },
      )

      return data
    },
  },
  updateTaskTemplate: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['updateTaskTemplate']) => {
      const { data } = await APIAxiosInstance.put(
        `tasks/admin/templates/${payload.id}`,
        {
          ...payload,
        },
      )

      return data
    },
  },

  // ========================== Task Template Version ==========================
  createTaskTemplateVersion: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['createTaskTemplateVersion']) => {
      const { data } = await APIAxiosInstance.post(
        `tasks/admin/templates/${payload.templateId}/versions`,
        payload,
      )
      return data
    },
  },
  activateTaskTemplateVersion: {
    mutationKey: null,
    mutationFn: async (
      payload: RequestTypes['activateTaskTemplateVersion'],
    ) => {
      const { data } = await APIAxiosInstance.post(
        `tasks/admin/templates/${payload.templateId}/activate`,
        { versionId: payload.versionId },
      )
      return data
    },
  },
  discardTaskTemplateVersion: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['discardTaskTemplateVersion']) => {
      const { data } = await APIAxiosInstance.post(
        `tasks/admin/templates/${payload.templateId}/versions/${payload.versionId}/discard`,
      )
      return data
    },
  },

  // ========================== Task Template Steps ==========================
  updateTaskTemplateSteps: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['updateTaskTemplateSteps']) => {
      const { data } = await APIAxiosInstance.put(
        `tasks/admin/templates/${payload.templateId}/versions/${payload.versionId}/steps`,
        { steps: payload.steps },
      )
      return data
    },
  },

  // ========================== Task Template Properties ==========================
  updateTaskTemplateProperties: {
    mutationKey: null,
    mutationFn: async (
      payload: RequestTypes['updateTaskTemplateProperties'],
    ) => {
      const { data } = await APIAxiosInstance.put(
        `tasks/admin/templates/${payload.templateId}/versions/${payload.versionId}/properties`,
        payload.properties,
      )
      return data
    },
  },

  // ========================== Task Template Role ==========================
  createTaskTemplateRole: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['createTaskTemplateRole']) => {
      const { data } = await APIAxiosInstance.post(
        `tasks/admin/templates/${payload.templateId}/versions/${payload.versionId}/roles`,
        payload,
      )
      return data
    },
  },

  updateTaskTemplateRole: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['updateTaskTemplateRole']) => {
      const { data } = await APIAxiosInstance.put(
        `tasks/admin/templates/${payload.templateId}/versions/${payload.versionId}/roles/${payload.roleId}`,
        payload,
      )
      return data
    },
  },

  deleteTaskTemplateRole: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['deleteTaskTemplateRole']) => {
      const { data } = await APIAxiosInstance.delete(
        `tasks/admin/templates/${payload.templateId}/versions/${payload.versionId}/roles/${payload.roleId}`,
      )
      return data
    },
  },
})

const taskTemplateKeys = mergeQueryKeys(
  taskTemplateQueryKeys,
  taskTemplateMutationKeys,
)['task-templates']

export { taskTemplateKeys }
