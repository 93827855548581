export type TaskTemplateStepType =
  | 'CREATION'
  | 'WORK'
  | 'APPROVAL'
  | 'CANCELLED'

export const TaskTemplateStepTypes: TaskTemplateStepType[] = [
  'WORK',
  'APPROVAL',
]
