import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { taskKeys, RequestTypes } from '@core/react-query/features/tasks/task'
import { Task, ITask } from '@core/entities/task/Task'
import { RequestErrorType } from '@core/utils/api-axios-instance'

const taskTransform: (task: ITask) => Task = (data) => {
  return Task.new(data)
}

export const useGetTaskById = (
  payload: RequestTypes['getTask'],
  options?: UseQueryOptions<ITask, RequestErrorType, Task>,
) => {
  return useQuery({
    select: taskTransform,
    ...taskKeys.byId(payload),
    ...options,
  })
}
