import { Type } from 'class-transformer'
import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { User } from '@core/entities/user'
import { TaskTemplateProperties } from '@core/entities/task/TaskTemplateProperties'
import { TaskTemplateRole } from '@core/entities/task/TaskTemplateRole'
import { ITaskTemplateVersion } from './ITaskTemplateVersion'
import { TaskTemplateVersionStatus } from './types'
import { TaskTemplateStepsWrapper } from './TaskTemplateStepsWrapper'
import { TemplateForm } from '@core/entities/template/TemplateForm'

export class TaskTemplateVersion extends Entity<ITaskTemplateVersion> {
  // This is the versionId
  id: number

  // This is the templateId
  templateId: number

  templateName: string

  version: number

  status: TaskTemplateVersionStatus

  @Type(() => TaskTemplateStepsWrapper)
  steps: TaskTemplateStepsWrapper

  @Type(() => TaskTemplateProperties)
  properties: TaskTemplateProperties

  createdOn: string

  modifiedOn: string

  @Type(() => User)
  createdBy: User

  @Type(() => User)
  modifiedBy: User

  @Type(() => TaskTemplateRole)
  versionRoles: { roles: TaskTemplateRole[] }

  public static new(payload: unknown): TaskTemplateVersion {
    const entity = plainToInstance(TaskTemplateVersion, payload)

    return entity
  }

  public isReadOnly(): boolean {
    return this.status !== 'DRAFT'
  }

  public getStatusChipColor() {
    switch (this.status) {
      case 'ACTIVE':
        return 'success'
      case 'ARCHIVED':
        return 'default'
      case 'DISCARDED':
        return 'warning'
      case 'DRAFT':
        return 'default'
      default:
        return 'default'
    }
  }

  public static statusString(status: TaskTemplateVersionStatus): string {
    switch (status) {
      case 'DRAFT':
        return 'Draft'
      case 'ARCHIVED':
        return 'Archived'
      case 'ACTIVE':
        return 'Active'
      case 'DISCARDED':
        return 'Discarded'
      default:
        return status
    }
  }

  public getStatusString(): string {
    switch (this.status) {
      case 'DRAFT':
        return 'Draft'
      case 'ARCHIVED':
        return 'Archived'
      case 'ACTIVE':
        return 'Active'
      case 'DISCARDED':
        return 'Discarded'
      default:
        return this.status
    }
  }

  public getCreationStepQuestions(): TemplateForm {
    return this.steps.taskSteps.find((step) => step.type === 'CREATION')
      ?.stepQuestions
  }
}
