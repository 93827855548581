import { localizedStrings } from '@core/strings'
import React, { useMemo } from 'react'
import { useTheme } from '@mui/material'
import { ChecklistTab } from '@modules/checklistDetails/tabs/ChecklistTab'
import { ChecklistTimelineTab } from '@modules/checklistDetails/tabs/ChecklistTimelineTab'
import {
  ArrowDropDown,
  Diversity3Outlined,
  Download,
  FileCopy,
  InfoOutlined,
} from '@mui/icons-material'
import { Box, Flex, Text } from '@fundamentals'
import { Button, Chip, DropdownButton, IconButton } from '@common/material'
import { NavigationAwareTabs } from '@elements'
import { ChecklistDetailsSidebar } from '@modules/checklistDetails/ChecklistDetailsSidebar'
import ClearIcon from '@mui/icons-material/Clear'
import { useModal } from '@common/GlobalModal'
import { useGetChecklistById } from '@core/react-query/features/checklists/checklist'
import {
  useChecklistPdfDownloadUrl,
  useCloneChecklist,
} from '@core/react-query/features/checklists/checklist'
import { useRouter } from 'next/router'
import { PageUrls } from '@core/page-urls'
import { LinkedDocumentsTab } from '@modules/checklistDetails/tabs/LinkedDocumentsTab'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import { BetaChecklistModal } from '@modules/checklistDetails/BetaChecklistModal'
import { useToast } from '@core/toast'
import { ChecklistBriefParticipantsModal } from '@modules/checklistDetails/ChecklistBriefParticipantsModal'
import { ChecklistListTabs } from '@core/entities/checklist/Checklist/types'
import {
  CommentsContext,
  createCommentsContextValue,
} from '@core/react-query/features/comments/hooks/CommentsContext'
import { TotalCommentsChip } from '@elements/Comments'
import { ObjectDetectionRobotChip } from '@elements/ObjectDetection/ObjectDetectionRobotChip'

type ChecklistDetailsActionsType = {
  icon: React.ReactElement
  name: string
  dataTest?: string
  onClick: () => void
}

type ComponentType = {
  checklistId: number
  isModal?: boolean
}

const ChecklistDetails: React.FC<ComponentType> = ({
  checklistId,
  isModal = false,
}) => {
  const theme = useTheme()
  const { hideModal, showModal } = useModal()
  const router = useRouter()

  const { data: checklist } = useGetChecklistById({ checklistId })
  const downloadMutation = useChecklistPdfDownloadUrl({
    onError: (e) => toast.error(e.message),
  })
  const cloneChecklist = useCloneChecklist()
  const { flags } = useFeatureFlags(['beta'])
  const toast = useToast()

  const commentsContext = createCommentsContextValue({
    id: checklistId,
    type: 'CHECKLIST',
  })

  const tabs = [
    {
      name: localizedStrings.checklist,
      label: localizedStrings.checklist,
      component: <ChecklistTab checklist={checklist} />,
    },
    {
      name: 'Timeline',
      label: 'Timeline',
      clearParams: true,
      component: <ChecklistTimelineTab checklistId={checklist?.id} />,
    },
    {
      name: 'LinkedDocuments',
      label: 'Linked Documents',
      component: <LinkedDocumentsTab checklist={checklist} />,
    },
  ]

  const handleDownload = async () => {
    const pdfDownloadUrl = await downloadMutation.mutateAsync({
      checklistId,
    })

    const toastId = Utils.GUID()
    toast.progress(localizedStrings.downloadingChecklist, {
      duration: 80 * 1000,
      id: toastId,
    })

    const fileGenerated = await Utils.pollForSuccessfulRequest(
      pdfDownloadUrl.urlDownload,
    )

    if (!fileGenerated) {
      toast.error(localizedStrings.checklistDownloadFailed, { id: toastId })
    } else {
      window.location.assign(pdfDownloadUrl.urlDownload)
      toast.success(localizedStrings.checklistDownloadComplete, { id: toastId })
    }
  }

  const handleClone = () => {
    cloneChecklist.mutate(
      {
        checklistId,
      },
      {
        onSuccess: () => {
          toast.success(localizedStrings.checklistCloned)
          router.push(
            PageUrls.checklists(checklist.organisationId, {
              tab: ChecklistListTabs.DRAFTS,
            }),
          )
        },
        onError: (e) => toast.error(e.message),
      },
    )
  }

  const secondaryActionsOptionsMemoised = useMemo(() => {
    const secondaryActionsOptions: ChecklistDetailsActionsType[] = []

    const downloadAction: ChecklistDetailsActionsType = {
      name: localizedStrings.downloadChecklist,
      icon: <Download />,
      dataTest: 'downloadChecklist',
      onClick: handleDownload,
    }

    const cloneAction: ChecklistDetailsActionsType = {
      name: localizedStrings.clone,
      icon: <FileCopy />,
      dataTest: 'clone-permit',
      onClick: () => handleClone(),
    }

    secondaryActionsOptions.push(downloadAction)
    if (checklist?.permittedActions?.includes('CLONE')) {
      secondaryActionsOptions.push(cloneAction)
    }

    if (checklist?.permittedActions?.includes('BRIEF_PARTICIPANT')) {
      secondaryActionsOptions.push({
        name: localizedStrings.briefParticipants,
        icon: <Diversity3Outlined />,
        dataTest: 'brief-participant',
        onClick: () =>
          showModal(<ChecklistBriefParticipantsModal checklist={checklist} />),
      })
    }

    const beta: ChecklistDetailsActionsType = {
      name: 'Beta',
      icon: <InfoOutlined />,
      dataTest: 'beta',
      onClick: () => {
        showModal(<BetaChecklistModal checklist={checklist} />)
      },
    }

    flags.beta && secondaryActionsOptions.push(beta)

    return secondaryActionsOptions
  }, [handleDownload])

  return (
    !!checklist && (
      <CommentsContext.Provider value={commentsContext}>
        <Box
          data-test='checklist-details'
          top={isModal ? 0 : '67px'}
          zIndex={10}
          position='sticky'
          bgcolor={isModal ? '#ffffff' : '#FAFAFA'}
          px='3'
          pb={1}
        >
          <Flex justifyContent='space-between' alignItems='center' mb={1.5}>
            <Text variant='h5'>
              {`${checklist?.template?.name} (V${checklist?.template?.currentVersion.version}) - ${checklist?.shortUUID}`}
            </Text>
            {isModal && (
              <IconButton aria-label='close-modal' onClick={hideModal}>
                <ClearIcon />
              </IconButton>
            )}
          </Flex>
          <Flex flexDirection='row' gap={1} alignItems={'center'}>
            <Box>
              <Chip color='primary' label={'Complete'} />
            </Box>
            <TotalCommentsChip />
            <ObjectDetectionRobotChip checklist={checklist} />
            <Box flexGrow={1} />
            <Box>
              <DropdownButton
                label={localizedStrings.actions}
                options={secondaryActionsOptionsMemoised.map(
                  (secondaryOptions) => ({
                    title: secondaryOptions.name,
                    value: secondaryOptions.name,
                    'data-test': secondaryOptions.dataTest,
                    onSelect: secondaryOptions.onClick,
                    icon: secondaryOptions.icon,
                  }),
                )}
                renderButton={(onClick) => (
                  <Button
                    variant='outlined'
                    data-test='secondary-checklist-actions'
                    endIcon={<ArrowDropDown />}
                    onClick={onClick}
                    sx={{
                      fontSize: 16,
                      background: theme.palette.legacy.white[10],
                      mr: 1,
                    }}
                  >
                    {localizedStrings.actions}
                  </Button>
                )}
              />
            </Box>
          </Flex>
        </Box>
        <Flex alignItems='flex-start'>
          <NavigationAwareTabs tabs={tabs} tabParam='tab' isModal={isModal} />
          <Box
            sx={{
              pl: 3,
              width: '23rem',
              position: 'sticky',
              top: isModal ? 123 : 183,
            }}
          >
            <ChecklistDetailsSidebar checklist={checklist} />
          </Box>
        </Flex>
      </CommentsContext.Provider>
    )
  )
}
export default ChecklistDetails
