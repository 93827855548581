import { localizedStrings } from '@core/strings'
import { Type, plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IProjectPlan } from './IProjectPlan'
import { ProjectPlanStatus, StatusFilterOption } from './types'
import { ProjectPlanArea } from '../ProjectPlanArea'
import { ProjectLocationStatus } from '../ProjectLocationNode'
import { SiteBoxDocumentPlanVersion } from '@core/entities/SiteBoxDocumentPlanVersion'

export class ProjectPlan extends Entity<IProjectPlan> {
  id: number

  locationPlanDocumentDownloadUrl: string

  locationPlanDocumentKey: string

  locationPlanImageDownloadUrl: string

  locationPlanImageKey: string

  status: ProjectPlanStatus

  pdfHeightInPoints: number

  pdfWidthInPoints: number

  imageWidthInPixels: number

  locationNode?: {
    id: string
    name: string
    locationPath: string
    status: ProjectLocationStatus
  }

  @Type(() => ProjectPlanArea)
  areas: ProjectPlanArea[]

  siteBoxPlanDocument?: {
    id: string
    name: string
    currentVersion: SiteBoxDocumentPlanVersion
  }

  siteBoxPlanDocumentVersion?: SiteBoxDocumentPlanVersion

  public static new(payload: unknown): ProjectPlan {
    const entity = plainToInstance(ProjectPlan, payload)

    return entity
  }

  public isPublished(): boolean {
    return this.status === 'PUBLISHED'
  }

  public isEditable(): boolean {
    return this.status === 'UNPUBLISHED'
  }

  public getSelectedZoneNames(selectedZoneIds: string[]): string {
    return this.areas
      .filter((area) => selectedZoneIds.includes(area.uuid))
      .map((area) => ` ${area.name}`)
      .toString()
  }

  public getStatusChipColor() {
    switch (this.status) {
      case 'ARCHIVED':
      case 'UNPUBLISHED':
        return 'default'
      default:
        return 'success'
    }
  }

  public getStatusString(): string {
    switch (this.status) {
      case 'UNPUBLISHED':
        return localizedStrings.unpublished
      case 'PUBLISHED':
        return localizedStrings.published
      case 'ARCHIVED':
        return localizedStrings.archived
      default:
        return this.status
    }
  }

  public getFilterStrings(): StatusFilterOption {
    switch (this.status) {
      case 'UNPUBLISHED':
        return 'Unpublished'
      case 'PUBLISHED':
        return 'Published'
      case 'ARCHIVED':
        return 'Archived'
      default:
        return this.status
    }
  }
}
