import { PageResponse } from '@core/entities/pagination'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import {
  taskTemplateKeys,
  RequestTypes,
} from '@core/react-query/features/tasks/templates'
import {
  ITaskTemplateVersion,
  TaskTemplateVersion,
} from '@core/entities/task/TaskTemplateVersion'

const dataTransformer = ({
  content,
  ...response
}: PageResponse<ITaskTemplateVersion>) => {
  return {
    ...response,
    content: content.map((template) => TaskTemplateVersion.new(template)),
  }
}

export const useGetTaskTemplateVersions = (
  params: RequestTypes['getTaskTemplateVersions'],
  options?: UseQueryOptions<
    PageResponse<ITaskTemplateVersion>,
    string,
    PageResponse<TaskTemplateVersion>
  >,
) => {
  return useQuery<
    PageResponse<ITaskTemplateVersion>,
    string,
    PageResponse<TaskTemplateVersion>
  >({
    select: dataTransformer,
    ...taskTemplateKeys.getTaskTemplateVersions(params),
    ...options,
  })
}
