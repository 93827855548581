import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { taskKeys, RequestTypes } from '@core/react-query/features/tasks/task'
import { Task } from '@core/entities/task/Task'
import { RequestErrorType } from '@core/utils/api-axios-instance'

export const useReassignTask = (
  options?: UseMutationOptions<
    Task,
    RequestErrorType,
    RequestTypes['reassignTask']
  >,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    ...taskKeys.reassignTask,
    ...options,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: taskKeys.byId({
          taskId: variables.id,
        }).queryKey,
      })
    },
  })
}
