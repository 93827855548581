import {
  ALL_PERMISSION_ASSIGNMENT_TYPES,
  PermissionAssignmentType,
} from '@core/entities/Groups/GroupsPermissions/types'
import { Entity } from '@core/entities/entity'
import { IGroupPermissionAssignment } from '@core/entities/Groups/GroupsPermissions/IGroupPermissionAssignment'
import { plainToInstance } from 'class-transformer'
import { localizedStrings } from '@core/strings'

export class GroupPermissionAssignment extends Entity<IGroupPermissionAssignment> {
  id: number
  type: PermissionAssignmentType
  enabled: boolean
  approval?: {
    id: number
    label: string
  }
  finalSignOff?: {
    id: number
    label: string
  }
  userProperty?: {
    label: string
  }
  templateRole?: {
    label: string
    id: number
    idText?: string // This is used for task templates
  }
  metadata?: {
    restrictedToProjectIds?: number[]
    restrictedToProjects?: { id: number }[]
  }

  public static new(payload: unknown): GroupPermissionAssignment {
    const entity = plainToInstance(GroupPermissionAssignment, payload)

    return entity
  }

  public getAssigmentTypeLabel(): string {
    switch (this.type) {
      // Permit permissions
      case 'PERMIT_DO_FINAL_SIGN_OFF':
        return localizedStrings.finalSignOff
      case 'PERMIT_REVIEW_EXTENSION':
        return localizedStrings.reviewExtension
      case 'REVIEW_PERMIT_TRANSFER_REQUEST':
        return localizedStrings.approveTransfer
      case 'PERMIT_APPROVE_SUSPENSION_RESUME_REQUEST':
        return localizedStrings.resumeAfterSuspension
      case 'PERMIT_APPROVE':
        return localizedStrings.approve
      case 'PERMIT_CANCEL':
        return localizedStrings.cancelPermit
      case 'PERMIT_INITIATION':
        return localizedStrings.raisePermit
      case 'MANAGE_PERMIT_PROJECT_CONFIGURATION':
        return localizedStrings.managePermitProjectConfiguration

      // Checklist permissions
      case 'CHECKLIST_INITIATION':
        return localizedStrings.raiseChecklist

      // Register permissions
      case 'ADD_REGISTER_ITEM':
        return localizedStrings.addItem
      case 'UPLOAD_REGISTER_ITEM':
        return localizedStrings.importViaCsv
      case 'ASSIGN_TO_USER_PROPERTY':
        return localizedStrings.assignToUserProperty
      case 'UPDATE_REGISTER_ITEM':
        return localizedStrings.updateItem
      case 'DOWNLOAD_REGISTER_ITEM':
        return localizedStrings.downloadCSV

      // Task permissions
      case 'ASSUME_TASK_ROLE':
        return localizedStrings.assumeTaskRole

      // Auth Group permissions
      case 'MANAGE_AUTH_GROUP_MEMBERS':
        return localizedStrings.manageMembers

      default:
        // We return the type as is (upper case and snake case)
        // This is so that we can see permissions that need to be explicitly mapped easily
        return this.type
    }
  }

  public getLabel(): string {
    let label = this.getAssigmentTypeLabel()
    if (this.approval && !this.approval.label.includes('Default Approval')) {
      label = `${label} (${this.approval.label})`
    }

    if (
      this.finalSignOff &&
      !this.finalSignOff.label.includes('Default Final Sign-Off')
    ) {
      label = `${label} (${this.finalSignOff.label})`
    }

    if (this.userProperty) {
      label = `${label} (${this.userProperty.label})`
    }

    if (this.templateRole && this.type !== 'ASSUME_TASK_ROLE') {
      label = `${localizedStrings.beBriefedAs} (${this.templateRole.label})`
    }

    if (this.type === 'ASSUME_TASK_ROLE') {
      label = `${label} (${this.templateRole?.label})`
    }

    return label
  }

  public getOrder(): number {
    // Find the index in the array
    let index = ALL_PERMISSION_ASSIGNMENT_TYPES.indexOf(this.type)
    if (index === -1) {
      // If we don't find the permission in the known list, put it at the end
      index = 1000
    }
    return index
  }
}
