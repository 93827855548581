import React from 'react'
import { localizedStrings } from '@core/strings'
import { Box, Text } from '@fundamentals'
import { MenuItem, TextField } from '@common/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { useGetTaskAssignableUsers } from '@core/react-query/features/tasks/task'
import { TaskAssignableUser } from '@core/entities/task/TaskAssignableUser'

export type TaskAssignUserPickerProps = TextFieldProps & {
  projectId: number
  templateId: number
  stepId: string
  value: TaskAssignableUser | null
  onChange?: (user: TaskAssignableUser) => void
  term?: string
}

const TaskAssignUserPicker: React.FC<TaskAssignUserPickerProps> = ({
  projectId,
  templateId,
  stepId,
  value,
  onChange,
  term,
  ...props
}) => {
  const {
    data: assignableUsersResponse,
    fetchNextPage,
    hasNextPage,
  } = useGetTaskAssignableUsers({
    projectId,
    templateId,
    stepId,
    term,
    size: 20,
  })

  const assignableUsers =
    assignableUsersResponse?.pages.flatMap((page) => page.content) ?? []

  const findUserById = (id: number) =>
    assignableUsers.find((user) => user.id === id)

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget
    if (scrollHeight - scrollTop === clientHeight) {
      if (hasNextPage) {
        fetchNextPage()
      }
    }
  }

  return (
    <TextField
      value={value?.id ?? ''}
      label={localizedStrings.assignTo}
      data-test='select-assignee-dropdown-btn'
      placeholder={localizedStrings.selectUser}
      onChange={({ target: { value: userId } }) => {
        const user = findUserById(Number(userId))
        if (user && onChange) {
          onChange(user)
        }
      }}
      select
      required
      fullWidth
      SelectProps={{
        MenuProps: {
          PaperProps: {
            onScroll: handleScroll,
            style: { maxHeight: 300 },
          },
        },
      }}
      {...props}
    >
      {assignableUsers.map((user, index) => (
        <MenuItem
          key={user.id}
          value={user.id}
          data-test={`select-assignee-dropdown-option-${index}`}
        >
          <Box>
            <Text>{`${user?.firstName} ${user?.lastName}`}</Text>
            <Text fontSize={15} color='grey'>
              {user?.email}
            </Text>
          </Box>
        </MenuItem>
      ))}
    </TextField>
  )
}

export { TaskAssignUserPicker }
