import {
  getAddAndBriefPermitParticipantsMutationOptions,
  getCreatePermitDraftMutationOptions,
  getIssuePermitMutationOptions,
  getReceivePermitMutationOptions,
  getRequestPermitMutationOptions,
  getSignOffPermitMutationOptions,
  getSubmitPermitChangesMutationOptions,
  getUpdatePermitDraftMutationOptions,
} from '@core/react-query/features/permits/permit'
import {
  getCreateChecklistMutationOptions,
  getCreateChecklistDraftMutationOptions,
  getUpdateChecklistDraftMutationOptions,
  getAddAndBriefChecklistParticipantsMutationOptions,
} from '@core/react-query/features/checklists/checklist'
import {
  getCreateTaskMutationOptions,
  getCreateTaskDraftMutationOptions,
  getUpdateTaskDraftMutationOptions,
} from '@core/react-query/features/tasks/task'
import { useEffect } from 'react'
import { QueryClient } from '@tanstack/react-query'

export type OfflineEnabledMutationVariables<T> = T & {
  offlineSubmittedOn?: string
}

export enum OfflineEntityType {
  PERMIT_SUBMISSION,
  CHECKLIST_SUBMISSION,
  PARTICIPANT_BRIEF,
  POST_PERMIT_CHECKS,
  TASK_SUBMISSION,
}

export const initialiseDefaultMutations = (queryClient: QueryClient) => {
  const defaultMutations = [
    getRequestPermitMutationOptions(queryClient),
    getSubmitPermitChangesMutationOptions(queryClient),
    getCreatePermitDraftMutationOptions(queryClient),
    getUpdatePermitDraftMutationOptions(queryClient),
    getIssuePermitMutationOptions(queryClient),
    getReceivePermitMutationOptions(queryClient),
    getCreateChecklistMutationOptions(queryClient),
    getCreateChecklistDraftMutationOptions(queryClient),
    getUpdateChecklistDraftMutationOptions(queryClient),
    getAddAndBriefChecklistParticipantsMutationOptions(queryClient),
    getAddAndBriefPermitParticipantsMutationOptions(queryClient),
    getSignOffPermitMutationOptions(queryClient),
    getCreateTaskMutationOptions(queryClient),
    getCreateTaskDraftMutationOptions(queryClient),
    getUpdateTaskDraftMutationOptions(queryClient),
  ]

  useEffect(() => {
    if (queryClient) {
      defaultMutations.forEach((mutation) => {
        queryClient.setMutationDefaults(mutation.mutationKey, mutation)
      })
    }
  }, [])
}
